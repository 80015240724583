import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
import { LayoutComponent } from './layout.component';
import { CollapseModule } from 'ngx-bootstrap';
import { OwlModule } from 'ngx-owl-carousel';
import { MalihuScrollbarModule } from 'ngx-malihu-scrollbar';
import { Select2Component } from 'angular-select2-component';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { AppService, AuthGuard, EqualValidator } from '../directive/index';
import { ContractsComponent, AddContractsComponent, AddAdminsComponent, AdminsComponent, UsersComponent, IssuesComponent, ProfileComponent, RequestsComponent, OrganizationsComponent, HelpComponent } from '../index';
import { AgmCoreModule } from '@agm/core';
import { Ng2PaginationModule } from 'ng2-pagination';
import { BusyModule } from 'angular2-busy';
import { ToastrModule } from 'ngx-toastr';
// import { ToastModule } from 'ng2-toastr/ng2-toastr';
import { SortablejsModule } from 'angular-sortablejs';
import { Ng2CloudinaryModule } from 'ng2-cloudinary';
import { FileUploadModule } from 'ng2-file-upload/file-upload/file-upload.module';
// import { FileUploadModule } from 'ng2-file-upload/ng2-file-upload.js';
// import { FileUploadModule } from 'ng2-file-upload';
import { NgxLoadingModule } from 'ngx-loading';
// import { Angular2CsvModule } from 'angular2-csv';
import { HighlightDirective } from '../highlight.directive';
// import { NgbDateCustomParserFormatter } from '../formatter';
import { NgxMasonryModule } from 'ngx-masonry';
import { DragDropModule } from '@angular/cdk/drag-drop';

const appRoutes: Routes = [
    {
        path: '', component: LayoutComponent,
        children: [
            { path: 'contracts', component: ContractsComponent },
            { path: 'contracts/add', component: AddContractsComponent },
            { path: 'admins/add', component: AddAdminsComponent },
            { path: 'admins', component: AdminsComponent },
            { path: 'users', component: UsersComponent },
            { path: 'issues', component: IssuesComponent },
            { path: 'profile', component: ProfileComponent },
            { path: 'requests', component: RequestsComponent },
            { path: 'help', component: HelpComponent },
            { path: 'organisation', component: OrganizationsComponent }
        ]
    }
];

@NgModule({
    declarations: [
        LayoutComponent,
        ContractsComponent,
        AddContractsComponent,
        AddAdminsComponent,
        AdminsComponent,
        UsersComponent,
        IssuesComponent,
        Select2Component,
        EqualValidator,
        ProfileComponent,
        RequestsComponent,
        HelpComponent,
        OrganizationsComponent,
        HighlightDirective
    ],

    imports: [
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,

        FileUploadModule,
        RouterModule.forRoot(appRoutes),
        NgbModule,
        CollapseModule,
        MalihuScrollbarModule.forRoot(),
        RouterModule.forRoot(appRoutes),
        AngularMultiSelectModule,
        OwlModule,
        Ng2PaginationModule,
        BusyModule,
        AgmCoreModule.forRoot({
            apiKey: "AIzaSyCOWHYbCCs9_t8g7oOozjTR75wNx5_xpb4",
            libraries: ["places"]
        }),
        ToastrModule.forRoot(),
        SortablejsModule,
        Ng2CloudinaryModule,
        NgxLoadingModule.forRoot({
            backdropBackgroundColour: 'transparent',
            backdropBorderRadius: '14px',
            primaryColour: '#004689',
            secondaryColour: '#004689',
            tertiaryColour: '#004689'
        }),
        NgxMasonryModule,
        DragDropModule,
        // NgbDateCustomParserFormatter
        // Angular2CsvModule

    ],
    exports: [RouterModule, EqualValidator],

    providers: [AppService, AuthGuard],

    bootstrap: [LayoutComponent]

})

export class LayoutModule { }