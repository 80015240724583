import { Component, ViewContainerRef } from '@angular/core';
import { Router } from '@angular/router';
import { routerTransition } from '../../router.animations';
import { MalihuScrollbarService } from 'ngx-malihu-scrollbar';
import { AppService } from '../../directive/app.service';
import { Action, AppConstants } from '../../directive/app.constants';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
// import { ToastsManager } from 'ng2-toastr/ng2-toastr';
import { NgxMasonryOptions } from 'ngx-masonry';

@Component({
    selector: 'users',
    templateUrl: './users.html',
    styleUrls: ['./users.component.scss'],
    animations: [routerTransition()]
})

export class UsersComponent {
    public masonryOptions: NgxMasonryOptions = {
        transitionDuration: '0s',
        // gutter: 20,
        // resize: true,
        initLayout: true,
        horizontalOrder: true
        // fitWidth: true
    };

    busy: Subscription;
    title = 'app';
    public scrollbarOptions = { axis: 'y', theme: 'minimal-dark', scrollButtons: { enable: true } };
    show_list: any[] = ['10', '20', '30', '40'];
    search_text: string = '';
    show_contract: any;
    page: any = 1;
    totalItem: number;
    showSelected: boolean;
    statusArray: Array<any> = [{ id: "0", itemName: "Active" }, { id: "1", itemName: "Inactive" }];
    selectedstatus: Array<any> = [];
    statusSettings = {};
    timer: any = null;
    constructor(public router: Router, private appService: AppService, public toastr: ToastrService, vcr: ViewContainerRef) {
        // this.toastr.setRootViewContainerRef(vcr);
        this.show_contract = '10';
        if (AppConstants.HISTROY_PAGE) {
            this.show_contract = AppConstants.HISTROY_PAGE.show_contract;
            this.page = AppConstants.HISTROY_PAGE.page;
            this.search_text = AppConstants.HISTROY_PAGE.search_text;
            AppConstants.HISTROY_PAGE = null;
        }

        this.statusSettings = {
            singleSelection: false,
            text: "Select Status",
            enableSearchFilter: false,
            badgeShowLimit: 1,
            classes: "myclass custom-class"
        };
    }
    UserData: Array<any> = [];
    TempUserData: Array<any> = [];
    ngOnInit() {
        // let skip = '0';
        // if (this.page > 1) {
        //     skip = Math.round(((this.page - 1) * this.show_contract)).toFixed(2);
        // }
        var selectedStatus = [];
        if (this.selectedstatus.length > 0) {
            for (let p = 0; p < this.selectedstatus.length; p++) {
                selectedStatus.push(this.selectedstatus[p].id);
            }

        }
        console.log(selectedStatus)


        let dummyArray = [];
        let me = this;
        let action = Action.GET_ADMINS_USER;
        let fd = new FormData();
        fd.append("search_text", me.search_text);
        fd.append("eFlag", '2');
        fd.append("iContractID", AppConstants.CONTRACT_ADMIN_CONTRACT);
        fd.append("userFlag", AppConstants.CONTRACT_ADMIN_EFLAG);
        fd.append("eStatus", JSON.stringify(selectedStatus));
        fd.append("skip", this.page);
        fd.append("limit", this.show_contract);

        me.busy = me.appService.remontServiceCall(action, fd)
            .subscribe(data => {
                if (data.type == true) {
                    this.UserData = data.resultData;
                    this.TempUserData = data.resultData;
                    this.totalItem = data.totalItem;
                    var start = 0;
                    var doThing = function (start) {
                        var datalength = data.resultData.length - 1;
                        for (var i = start; i < data.resultData.length; i++) {
                            var userData = data.resultData[i];
                            // dummyArray.push({ '_id': userData._id, count: userData.count, contractcount: 0 });
                            let fd = new FormData();
                            fd.append("iUserID", data.resultData[i]._id._id);
                            let action = Action.GET_CONTRACT_COUNT;
                            me.appService.remontServiceCall(action, fd)
                                .subscribe(data => {
                                    if (data.type == true) {
                                        userData.contractcount = data.count;
                                        doThing(i + 1);
                                    } else {
                                        userData.contractcount = 0;
                                        console.log(data.message);
                                        doThing(i + 1);
                                    }
                                    if (i == datalength) {
                                        // me.UserData = dummyArray;
                                        // me.TempUserData = dummyArray;
                                    }
                                }, error => {

                                });
                            break;
                        }
                    }
                    doThing(start);
                } else {
                    console.log("data.resultData");
                }
            }, error => {

            });
    }
    pageChange(event) {
        this.page = event;
        this.ngOnInit();
    }
    searchUser() {
        clearTimeout(this.timer);
        var me = this;
        this.timer = setTimeout(function () {
            me.page = event;
            me.ngOnInit()
        }, 500);
    }
    activeDeactiveUser(id, event) {
        let status;
        if (event.target.checked == true) {
            status = '0';
        }
        else {
            status = '1';
        }
        let fd = new FormData();
        let action = Action.ACTIVE_ADMIN_USER;
        fd.append("estatus", status);
        fd.append("iUserID", id);
        this.busy = this.appService.remontServiceCall(action, fd)
            .subscribe(data => {
                if (data.type == true) {
                    if (event.target.checked == true) {
                        this.toastr.success('User activated successfully.');
                    } else {
                        this.toastr.success('User deactivated successfully.');
                    }
                    this.ngOnInit();
                } else {
                    console.log('data.message');
                }
            }, error => {

            });
    }
    loginPermissionAsGenerelUser(id, event) {
        let status;
        let fd = new FormData();
        let action = Action.UPDATE_LOGIN_PERMISSION;
        fd.append("eLoginStatus", event.target.checked);
        fd.append("iUserID", id);
        this.busy = this.appService.remontServiceCall(action, fd)
            .subscribe(data => {
                if (data.type == true) {
                    if (event.target.checked == true) {
                        this.toastr.success('Permission is approved');
                    } else {
                        this.toastr.success('Permission is rejected');
                    }

                    this.ngOnInit();
                } else {
                    console.log('data.message');
                }
            }, error => {

            });
    }
    deleteUser(id) {
        let fd = new FormData();
        fd.append("iUserID", id);
        fd.append("status", '0');
        let action = Action.DELETE_ADMIN_USER;
        this.busy = this.appService.remontServiceCall(action, fd)
            .subscribe(data => {
                if (data.type == true) {
                    this.toastr.success(data.message);
                    this.ngOnInit();
                } else {
                    console.log(data.message);
                }
            }, error => {

            });

    }
    showIssues(user) {
        let param = { 'search_text': this.search_text, 'show_contract': this.show_contract, 'page': this.page }
        AppConstants.HISTROY_PAGE = param;
        AppConstants.TEMP_GENERAL_USER = user._id;
        AppConstants.TEMP_GENERAL_USER_NAME = 'by ' + user.vFirstName + ' ' + user.vLastName;
    }
    userContract: Array<any> = [];
    username: any;
    ShowContract(user) {
        //        console.log(user)
        this.username = user
        this.showSelected = true;
        let fd = new FormData();
        fd.append("iUserID", user._id);
        let action = Action.GET_CONTRACT;
        this.busy = this.appService.remontServiceCall(action, fd)
            .subscribe(data => {
                if (data.type == true) {

                    this.userContract = [];
                    var currentContract = JSON.parse(localStorage.getItem('userData')).Contracts;
                    for (var i = 0; i < data.resultData.length; i++) {
                        if (AppConstants.CONTRACT_ADMIN_EFLAG != '0') {
                            let index = currentContract.findIndex(order => order.iContractID === data.resultData[i]._id);
                            //                        console.log(index);
                            if (index != -1) {
                                this.userContract.push({
                                    '_id': data.resultData[i]._id, 'vContractName': data.resultData[i].vContractName, 'Services': data.resultData[i].Services,
                                    'Facility': data.resultData[i].Facility, 'Domains': data.resultData[i].Domains, 'flag': 1
                                });
                            } else {
                                this.userContract.push({
                                    '_id': data.resultData[i]._id, 'vContractName': data.resultData[i].vContractName, 'Services': data.resultData[i].Services,
                                    'Facility': data.resultData[i].Facility, 'Domains': data.resultData[i].Domains, 'flag': 0
                                });
                            }
                        } else {
                            this.userContract.push({
                                '_id': data.resultData[i]._id, 'vContractName': data.resultData[i].vContractName, 'Services': data.resultData[i].Services,
                                'Facility': data.resultData[i].Facility, 'Domains': data.resultData[i].Domains, 'flag': 1
                            });
                        }
                    }
                    //                    this.userContract = data.resultData;
                } else {
                    console.log(data.message);
                }
            }, error => {

            });
    }
    HideButton() {
        this.showSelected = false;
    }
    unAssign(contract, user) {
        let fd = new FormData();
        fd.append("iUserID", user._id);
        fd.append("iContractID", contract._id);
        let action = 'delete/usercontract/';
        this.busy = this.appService.remontServiceCall(action, fd)
            .subscribe(data => {
                if (data.type == true) {
                    this.toastr.success(data.message);
                    if (AppConstants.CONTRACT_ADMIN_EFLAG == '2') {
                        var currentContract = JSON.parse(localStorage.getItem('userData')).Contracts;
                        let index = currentContract.findIndex(order => order.iContractID === contract._id);
                        console.log(index);
                        if (index != -1) {
                            currentContract.splice(index, 1);
                            let kittensFromLocalStorage = JSON.parse(localStorage.getItem("userData"));
                            console.log(currentContract);
                            kittensFromLocalStorage.Contracts = currentContract;

                            let contract = [];
                            for (let j = 0; j < currentContract.length; j++) {
                                contract.push(currentContract[j].iContractID);
                            }
                            AppConstants.CONTRACT_ADMIN_CONTRACT = contract.join(',');
                            localStorage.setItem("userData", JSON.stringify(kittensFromLocalStorage));
                        }
                    }
                    this.ShowContract(user);
                    this.ngOnInit();
                } else {
                    this.toastr.success(data.message);
                }
            }, error => {

            });
    }
    onItemSelect(event) {
        // this.selectedstatus = [];
        this.page = 1;
        this.ngOnInit();
    }
}
