import { Component, ViewChild, ViewContainerRef } from '@angular/core';
import { Router } from '@angular/router';
import { routerTransition } from '../../router.animations';
import { MalihuScrollbarService } from 'ngx-malihu-scrollbar';
import { OwlCarousel } from 'ngx-owl-carousel';
import { AppService } from '../../directive/app.service';
import { Action, AppConstants } from '../../directive/app.constants';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
// import { ToastsManager } from 'ng2-toastr/ng2-toastr';

import { Angular2Csv } from 'angular2-csv/Angular2-csv';
import * as moment from 'moment';
import { NgbDateParserFormatter, NgbDate, NgbDatepickerConfig } from "@ng-bootstrap/ng-bootstrap";
import { NgbDateCustomParserFormatter } from '../../formatter';

@Component({
    selector: 'issues',
    templateUrl: './issues.html',
    styleUrls: ['./issues.component.scss'],
    animations: [routerTransition()],
    providers: [
        { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter }
    ]
})

export class IssuesComponent {
    busy: Subscription;
    @ViewChild('owlElement', { static: false }) owlElement: OwlCarousel
    @ViewChild('owlElementimage', { static: false }) owlElementimage: OwlCarousel
    title = 'app';
    show_issues: any;
    owlOption: any;
    public scroll_hr = { axis: 'x', theme: 'minimal-light', scrollButtons: { enable: true } };
    public scroll_vr = { axis: 'y', theme: 'minimal-dark', scrollButtons: { enable: true } };
    // select_contract: any[] = [];
    // select_contract_admin: any[] = [];
    statusArray: Array<any> = [{ id: "0", itemName: "Pending" }, { id: "1", itemName: "Resolved" }, { id: "2", itemName: "Queried" }];
    showSelected: boolean;
    issuesData: Array<any> = [];
    search_text: string = '';
    show_list: any[] = ['10', '20', '50', '100'];
    page: any = 1;
    contractArray: Array<any> = [];
    adminArray: Array<any> = [];
    contractSettings = {};
    adminSettings = {};
    statusSettings = {};
    selectedcontract: Array<any> = [];
    selectedadmin: Array<any> = [];
    selectedStatus: Array<any> = [];
    showSelectedModal: boolean;
    eFalg: any = AppConstants.CONTRACT_ADMIN_EFLAG;
    queryTitle: any;
    queryDesc: any;
    showBack: boolean = false;
    PopContractTextValue: String = '';
    PopIssueValue: any;
    USERNAME: any;
    start_date: any = '';
    end_date: any = '';
    totalItem: number;
    timer: any = null;
    constructor(public router: Router, private appService: AppService, public toastr: ToastrService, vcr: ViewContainerRef, private config: NgbDatepickerConfig) {
        // this.toastr.setRootViewContainerRef(vcr);
        this.showSelected = false;
        this.show_issues = '100';
        this.owlOption = { items: 5, stagePadding: 0, smartSpeed: 10, loop: false, margin: 15, dots: false, nav: false };
        this.contractSettings = {
            singleSelection: false,
            text: "Select Contract",
            badgeShowLimit: 1,
            enableSearchFilter: false,
            classes: "myclass custom-class"
        };
        this.adminSettings = {
            singleSelection: false,
            text: "Select Contract Admins",
            enableSearchFilter: false,
            badgeShowLimit: 1,
            classes: "myclass custom-class"
        };
        this.statusSettings = {
            singleSelection: false,
            text: "Select Status",
            enableSearchFilter: false,
            badgeShowLimit: 1,
            classes: "myclass custom-class"
        };
        this.getContract();
        if (AppConstants.HISTROY_PAGE) {
            this.showBack = true;
        }
        this.USERNAME = AppConstants.TEMP_GENERAL_USER_NAME;

        const currentDate = new Date();
        this.start_date = {
            year: currentDate.getFullYear(),
            month: 1,
            day: 1
        };
        this.end_date = {
            year: currentDate.getFullYear(),
            month: currentDate.getMonth() + 1,
            day: currentDate.getDate()
        };

    }
    ngOnInit() {
        // this.issuesData = [];
        if (AppConstants.TEMP_CONTRACT) {
            this.selectedcontract = [{ 'id': AppConstants.TEMP_CONTRACT._id, 'itemName': AppConstants.TEMP_CONTRACT.vContractName }];
        }
        if (AppConstants.TEMP_USER) {
            this.selectedadmin = [AppConstants.TEMP_USER];
        }

        let contractID = AppConstants.CONTRACT_ADMIN_CONTRACT;
        if (this.selectedcontract.length > 0) {
            let contract = [];
            for (let j = 0; j < this.selectedcontract.length; j++) {
                contract.push(this.selectedcontract[j].id);
            }
            contractID = contract.join(',');
        }
        // console.log(this.selectedadmin);
        if (this.selectedadmin.length > 0) {
            let contractadmin = [];
            for (let p = 0; p < this.selectedadmin.length; p++) {
                contractadmin.push(this.selectedadmin[p].id);
            }
            contractID = contractadmin.join(',');
        }
        // console.log(this.selectedStatus);
        var statusData = [];
        if (this.selectedStatus.length > 0) {
            for (let a = 0; a < this.selectedStatus.length; a++) {
                statusData.push(this.selectedStatus[a].id);
            }

        }
        // console.log(statusData)

        var startDate = '';
        var endDate = '';
        if (this.start_date) {
            startDate = this.start_date.year + '-' + this.start_date.month + '-' + this.start_date.day;
        }
        if (this.end_date) {
            endDate = this.end_date.year + '-' + this.end_date.month + '-' + this.end_date.day;
        }
        // let skip = '0';
        // if (this.page > 1) {
        //     skip = Math.round(((this.page - 1) * this.show_issues)).toFixed(2);
        // }

        let action = Action.GET_ISSUES;
        let fd = new FormData();
        fd.append("search_text", this.search_text);
        fd.append("iContractID", contractID);
        fd.append("start_date", startDate);
        fd.append("end_date", endDate);
        fd.append("iUserID", AppConstants.TEMP_GENERAL_USER);
        fd.append("eFlag", AppConstants.CONTRACT_ADMIN_EFLAG);
        fd.append("eStatus", JSON.stringify(statusData));
        fd.append("limit", this.show_issues);
        fd.append("skip", this.page);
        this.busy = this.appService.remontServiceCall(action, fd)
            .subscribe(data => {
                if (data.type == true) {
                    this.issuesData = data.resultData;
                    this.totalItem = data.totalItem;
                } else {
                }
            }, error => {
            });
    }
    pageChange(event, a) {
        console.log(event)
        console.log(a)
        this.page = event;
        this.ngOnInit();
    }
    getContract() {
        let action = Action.GET_ADMIN_CONTRACT;
        let fd = new FormData();
        fd.append("iContractID", AppConstants.CONTRACT_ADMIN_CONTRACT);
        fd.append("eFlag", AppConstants.CONTRACT_ADMIN_EFLAG);
        this.appService.remontServiceCall(action, fd)
            .subscribe(data => {
                if (data.type == true) {
                    this.contractArray = data.resultData;
                } else {
                }
                this.getContractAdmin();
            }, error => {
            });
    }
    getContractAdmin() {
        let action = Action.GET_CONTRACT_ADMIN;
        let fd = new FormData();
        this.appService.remontServiceCall(action, fd)
            .subscribe(data => {
                if (data.type == true) {
                    this.adminArray = data.resultData;
                } else {
                }
            }, error => {
            });
    }

    searchContract() {
        clearTimeout(this.timer);
        var me = this;
        this.timer = setTimeout(function() {
            me.page = 1;
            me.ngOnInit()
        }, 500);
        // this.ngOnInit();
    }
    onItemSelect(event) {
        this.page = 1;
        this.selectedcontract = [];
        this.ngOnInit();
    }
    onContractSelect(event) {
        this.page = 1;
        this.selectedadmin = [];
        this.ngOnInit();
    }
    onSelectStatus(event) {
        this.page = 1;
        // this.selectedStatus = [];
        this.ngOnInit();
    }
    onDeSelectStatus(event) {
        this.page = 1;
        this.selectedStatus = [];
        this.ngOnInit();
    }
    mouseWheelFunc(event: any) {
        var event = window.event || event; // old IE support
        var delta = Math.max(-1, Math.min(1, (event.wheelDelta || -event.detail)));
        if (delta > 0) {
            this.owlElement.trigger('next.owl.carousel', [3000]);

        } else if (delta < 0) {
            this.owlElement.trigger('prev.owl.carousel', [3000]);
        }
        if (event.preventDefault) {
            event.preventDefault();
        }
    }
    mouseWheelFuncImage(event: any) {
        var event = window.event || event; // old IE support
        var delta = Math.max(-1, Math.min(1, (event.wheelDelta || -event.detail)));
        if (delta > 0) {
            this.owlElementimage.trigger('next.owl.carousel', [3000]);

        } else if (delta < 0) {
            this.owlElementimage.trigger('prev.owl.carousel', [3000]);
        }
        if (event.preventDefault) {
            event.preventDefault();
        }
    }

    ShowButton(issue) {
        // this.PopContractTextValue = contract;

        let action = Action.GET_ISSUE_DETAILS;
        let fd = new FormData();
        fd.append("iIssueID", issue._id);
        this.busy = this.appService.remontServiceCall(action, fd)
            .subscribe(data => {
                if (data.type == true) {
                    this.PopIssueValue = data.resultData;
                    this.showSelected = true;
                } else {
                }
            }, error => {
            });
        // this.showSelected = true;
        // this.PopIssueValue = issue;

    }

    HideButton() {
        this.showSelected = false;
    }
    checkUncheck(value) {
        if (value == '0') {
            return false;
        } else {
            return true;
        }
    }
    statusUpdate(data, event) {
        let userId = JSON.parse(localStorage.getItem('userData'))._id;
        let status;
        if (event == true) {
            status = '1';
        } else {
            status = '0';
        }
        let action = Action.UPDATE_STATUS;
        let fd = new FormData();
        fd.append("iIssueID", data._id);
        fd.append("iResolveUserID", userId);
        fd.append("eStatus", status);
        this.busy = this.appService.remontServiceCall(action, fd)
            .subscribe(data => {
                if (data.type == true) {
                    this.toastr.success(data.message);
                    this.ngOnInit();
                } else {
                    this.toastr.success(data.message);
                }
            }, error => {
            });

    }
    HideModal() {
        this.showSelectedModal = false;
    }
    ShowModal(data) {

        this.queryTitle = data.vTitle
        this.queryDesc = data.iQueryID[0].vText
        this.showSelectedModal = true;

    }
    ngOnDestroy() {
        this.selectedcontract = [];
        this.selectedadmin = [];
        AppConstants.TEMP_GENERAL_USER = '';
        AppConstants.TEMP_CONTRACT = null;
        AppConstants.TEMP_USER = null;
        AppConstants.TEMP_GENERAL_USER_NAME = '';
    }
    back() {
        window.history.back()
    }
    getCount(data) {
        return data.length;
    }
    getFormatDate(dateDate) {

        return moment(dateDate).format('DD-MM-YYYY HH:mm')

    }
    chnageDate() {
        setTimeout(() => {
            if (this.start_date && this.end_date) {
                this.ngOnInit();
            }
        }, 500)
    }
    resetDateFilter() {
        this.start_date = {
            year: new Date().getFullYear(),
            month: 1,
            day: 1
        };
        this.end_date = {
            year: new Date().getFullYear(),
            month: new Date().getMonth() + 1,
            day: new Date().getDate()
        };
        this.ngOnInit();
    }

    downlaodSCV() {

        let csvarray = [];
        var options = {
            headers: ['Contract Name', 'Title', 'Description', 'Services', 'Facility', 'UrgencyCode', 'Rectification Time', 'Response Time', 'Urgency Text', 'User Name ', 'User Email', 'Location', 'Status', 'Created Date', 'Queried']
        };
        if (AppConstants.TEMP_CONTRACT) {
            this.selectedcontract = [{ 'id': AppConstants.TEMP_CONTRACT._id, 'itemName': AppConstants.TEMP_CONTRACT.vContractName }];
        }
        if (AppConstants.TEMP_USER) {
            this.selectedadmin = [AppConstants.TEMP_USER];
        }

        let contractID = AppConstants.CONTRACT_ADMIN_CONTRACT;
        if (this.selectedcontract.length > 0) {
            let contract = [];
            for (let j = 0; j < this.selectedcontract.length; j++) {
                contract.push(this.selectedcontract[j].id);
            }
            contractID = contract.join(',');
        }
        if (this.selectedadmin.length > 0) {
            let contractadmin = [];
            for (let p = 0; p < this.selectedadmin.length; p++) {
                contractadmin.push(this.selectedadmin[p].id);
            }
            contractID = contractadmin.join(',');
        }

        var startDate = '';
        var endDate = '';
        if (this.start_date != '') {
            startDate = this.start_date.year + '-' + this.start_date.month + '-' + this.start_date.day
        }
        if (this.end_date != '') {
            endDate = this.end_date.year + '-' + this.end_date.month + '-' + this.end_date.day
        }

        let action = Action.GET_ISSUES;
        let fd = new FormData();
        fd.append("search_text", this.search_text);
        fd.append("iContractID", contractID);
        fd.append("start_date", startDate);
        fd.append("end_date", endDate);
        fd.append("iUserID", AppConstants.TEMP_GENERAL_USER);
        fd.append("eFlag", AppConstants.CONTRACT_ADMIN_EFLAG);
        fd.append("skip", '0');
        fd.append("eStatus", JSON.stringify([]));
        fd.append("limit", this.totalItem.toString());

        this.busy = this.appService.remontServiceCall(action, fd)
            .subscribe(data => {
                if (data.type == true) {
                    let value = data.resultData;
                    for (let i = 0; i < value.length; i++) {
                        var status = '';
                        if (value[i].eStatus == 0) {
                            status = 'Pending'
                        } else {
                            status = 'Resolved'
                        }
                        var text = '';
                        if (value[i].iQueryID.length > 0) {
                            text = value[i].iQueryID[0].vText;
                        }
                        var date = this.getFormatDate(value[i].dCreatedDate);
                        csvarray.push({
                            'vContractName': value[i].iContractID.vContractName, 'vTitle': value[i].vTitle, 'vDescription': value[i].vDescription, 'vServices': value[i].vServices, 'vFacility': value[i].vFacility,
                            'vUrgencyCode': value[i].vUrgencyCode, 'vRectificationTime': value[i].vRectificationTime,
                            'vResponseTime': value[i].vResponseTime, 'vUrgencyText': value[i].vUrgencyText,
                            'Username': value[i].iUserID.vFirstName + " " + value[i].iUserID.vLastName, 'User Email': value[i].iUserID.vEmail,
                            'vLocation': value[i].vLocation, 'eStatus': status, 'vDate': date, 'query': text
                        })
                    }


                    var today = new Date();
                    var dd = today.getDate();
                    var mm = today.getMonth() + 1; //January is 0!
                    var y = today.getFullYear();
                    let filename = 'GeneralUser_' + dd + '_' + mm + '_' + y;
                    new Angular2Csv(csvarray, filename, options);
                } else {
                }
            }, error => {
            });
    }
    deleteIssue(issueID) {
        let action = Action.DELETE_ISSUE;
        let fd = new FormData();
        fd.append("iIssueID", issueID);
        this.busy = this.appService.remontServiceCall(action, fd)
            .subscribe(data => {
                if (data.type == true) {
                    this.toastr.success(data.message);
                    this.ngOnInit();
                } else {
                    this.toastr.success(data.message);
                }
            }, error => {
            });
    }

    getImageSrc(img: any): string {
        const base64Pattern = /^data:image\/[^;]+;base64,/;
    
        if (typeof img === 'string' && !base64Pattern.test(img)) {
          // If the image is a filename from Cloudinary
          const cloudinaryPath = 'https://res.cloudinary.com/zudu/image/upload/PFIHelpDesk/Mobile';
          return `${cloudinaryPath}/${img}`;
        } else if (typeof img === 'object' && base64Pattern.test(img.key)) {
          // If the image is a base64 string
          return img.key;
        } else {
          // Return a placeholder or an empty string if the format is not recognized
          return '';
        }
      }
    
    goToLink(url: string) {
        var image = new Image();
        image.src = url
        var w = window.open("");
        w.document.write(image.outerHTML);
    }
    onDateSelection() {
        // this.config.minDate = this.start_date;
        if (this.start_date && this.end_date) {
            this.ngOnInit();
        }
        //config.maxDate = { year: 2099, month: 12, day: 31 };
        // this.config.minDate = date
        // this.config.outsideDays = 'hidden';
        // var me = this;
        // if (this.start_date != '' && this.end_date != '') {
        //     let startDate = new Date(this.start_date.year + '-' + this.start_date.month + '-' + this.start_date.day);
        //     let endDate = new Date(this.end_date.year + '-' + this.end_date.month + '-' + this.end_date.day);
        //     // console.log(startDate)
        //     // console.log(endDate)
        //     if (startDate > endDate) {
        //         console.log('jaimin')
        //         setTimeout(function () {
        //             me.start_date = date;
        //             me.end_date = '';
        //         }, 200);
        //     }
        // }
    }
    isOpen() {
        console.log(this.config.minDate);
        // if (!this.config.minDate) {
        this.config.minDate = null;
        // }
        console.log('jaimin')
    }

}