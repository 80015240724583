import { Component, NgZone, ViewChild, ElementRef, ViewContainerRef } from '@angular/core';
import { Router } from '@angular/router';
import { routerTransition } from '../../router.animations';
import { MalihuScrollbarService } from 'ngx-malihu-scrollbar';
import { AppService } from '../../directive/app.service';
import { Action, AppConstants } from '../../directive/app.constants';
import { Subscription } from 'rxjs';
// import { } from 'googlemaps';
import { MapsAPILoader } from '@agm/core';
import { ToastrService } from 'ngx-toastr';
// import { ToastsManager } from 'ng2-toastr/ng2-toastr';

import { CloudinaryOptions, CloudinaryUploader } from 'ng2-cloudinary';
import { FileUploader } from 'ng2-file-upload';
import { AngularMultiSelect } from 'angular2-multiselect-dropdown';

@Component({
    selector: 'add-contracts',
    templateUrl: './add-contracts.html',
    styleUrls: ['./add-contracts.component.scss'],
    animations: [routerTransition()]
})

export class AddContractsComponent {

    @ViewChild(AngularMultiSelect, { static: false }) folderDropdown: AngularMultiSelect;
    @ViewChild("search", { static: false })
    public searchElementRef: ElementRef;
    busy: Subscription;
    title = 'app';
    showSelected: boolean;
    public scrollbarOptions = { axis: 'y', theme: 'minimal-light', scrollButtons: { enable: true }, mouseWheel: { enable: true } };
    public domain_scroll = { axis: 'y', theme: 'minimal-light', scrollButtons: { enable: true } };
    public urgency_scroll = { axis: 'y', theme: 'minimal-light', scrollButtons: { enable: true } };
    contract = {
        id: '',
        vContractNumber: '',
        vContractName: '',
        vAddress: '',
        Domains: '',
        Services: '',
        tempServices: '',
        tempFacility: '',
        Facility: '',
        vUrgencyCode: '',
        vTempUrgencyCode: '',
        vRectificationTime: '',
        vUrgencyHelpText: '',
        vResponseTime: '',
        vServiceEmail: '',
        vContractLogo: ''
    }
    DomainsData: Array<any> = [];
    ServicesData: Array<any> = [];
    FacilityData: Array<any> = [];
    FacilityTempData: Array<any> = [];
    public latitude: any;
    public longitude: any;
    public zoom: number;
    buttonText: string = 'Submit'
    titleText: string = 'Add Contract'
    facilitySelect = {};
    selectedfacility: Array<any> = [];
    selectedservices: Array<any> = [];
    ServicesTempData: Array<any> = [];
    ServicesSelect = {};
    AddCodeButton: string = 'Add New'

    AddFacilityButton: string = 'Add'
    AddServiceButton: string = 'Add'
    AddUrgencyButton: string = 'Add'
    innerWidth: any = window.innerWidth;
    //new code added by jaimin 28/4/2018 *--------*
    tempUrgencyData: Array<any> = [];
    //    *----------*
    organizations: Array<any> = [];
    domains: Array<any> = [];
    Permissions: any = {
        Organizations: [],
        Domains: []
    };
    contractLogo: '';
    public loading = false;
    uploader: CloudinaryUploader = new CloudinaryUploader(
        new CloudinaryOptions({ cloudName: AppConstants.ClOUD_NAME, uploadPreset: AppConstants.PRESENT_NAME })
    );
    constructor(
        public router: Router,
        private mapsAPILoader: MapsAPILoader,
        private ngZone: NgZone,
        private appService: AppService,
        public toastr: ToastrService,
        vcr: ViewContainerRef,
        private mScrollbarService: MalihuScrollbarService
    ) {
        // this.toastr.setRootViewContainerRef(vcr);
        this.zoom = 12;
        //        this.setCurrentPosition();
        this.mapsAPILoader.load().then(() => {
            let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
                types: ["address"]
            });
            autocomplete.addListener("place_changed", () => {
                this.ngZone.run(() => {
                    //get the place result
                    let place: google.maps.places.PlaceResult = autocomplete.getPlace();

                    //verify result
                    if (place.geometry === undefined || place.geometry === null) {
                        return;
                    }

                    //set latitude, longitude and zoom
                    this.latitude = place.geometry.location.lat();
                    this.longitude = place.geometry.location.lng();
                    this.zoom = 12;
                });
            });
        });
        this.facilitySelect = {
            singleSelection: true,
            text: "Select Facility",
            enableSearchFilter: false,
            classes: "facility_drop"
        };
        this.ServicesSelect = {
            singleSelection: true,
            text: "Select Services",
            enableSearchFilter: false,
            classes: "facility_drop"
        };
        this.uploader.onAfterAddingFile = (item: any) => {
            this.loading = true;
            item.url = this.uploader.options.url;
            this.upload()
        };
    }
    upload() {
        this.uploader.uploadAll();
        this.uploader.onSuccessItem = (item: any, response: string, status: number, headers: any): any => {
            let res: any = JSON.parse(response);
            console.log(res);
            //            this.contractLogo = res.url;
            //            console.log('v' + res.version + '/' + res.public_id + '.' + res.format);
            this.contract.vContractLogo = 'v' + res.version + '/' + res.public_id + '.' + res.format;
            this.loading = false;
        }
        this.uploader.onErrorItem = function (fileItem, response, status, headers) {
            console.info('onErrorItem', fileItem, response, status, headers);
        };
    }

    ngOnInit() {

        if (localStorage.getItem('contractData')) {
            let data = JSON.parse(localStorage.getItem('contractData'));
            this.contract.id = data._id;
            this.contract.vContractNumber = data.vContractNumber;
            this.contract.vContractName = data.vContractName;
            this.contract.vAddress = data.vAddress;
            this.contract.vContractLogo = data.vContractLogo;
            if (data.vContractLogo != '' && data.vContractLogo != undefined) {
                this.contract.vContractLogo = data.vContractLogo;
                this.contractLogo = data.vLogoURL + data.vContractLogo;
            }

            this.DomainsData = data.Domains;
            this.ServicesData = data.Services;
            this.FacilityData = data.Facility;
            this.Permissions = data.Permissions ? data.Permissions : {
                Organizations: [],
                Domains: []
            };
            for (let i = 0; i < data.Facility.length; i++) {
                this.FacilityTempData.push({ id: '', itemName: data.Facility[i].vFacilityName });
            }

            for (let j = 0; j < data.Services.length; j++) {
                this.ServicesTempData.push({ id: '', itemName: data.Services[j].vServiceName });

                //                new code added by jaimin 28/4/2018 *--------*
                for (let k = 0; k < data.Services[j].UrgencyCode.length; k++) {
                    this.tempUrgencyData.push({
                        vServiceName: data.Services[j].vServiceName,
                        vUrgencyCode: data.Services[j].UrgencyCode[k].vUrgencyCode, vUrgencyHelpText: data.Services[j].UrgencyCode[k].vUrgencyHelpText,
                        vResponseTime: data.Services[j].UrgencyCode[k].vResponseTime, vRectificationTime: data.Services[j].UrgencyCode[k].vRectificationTime
                    })
                }
                //                *--------*
            }

            this.latitude = data.latitude;
            this.longitude = data.latitude;
            this.buttonText = 'Update';
            this.titleText = 'Update Contract';
            this.getOrganizations(this.getPermissions.bind(this));
            this.getDomains(this.getPermissions.bind(this));
        }
        else {
            this.setCurrentPosition();
            this.getOrganizations();
            this.getDomains();
        }
    }

    ShowButton() {
        if (this.AddCodeButton == 'Cancel') {
            this.showSelected = false;
            this.AddCodeButton = 'Add New';
        } else {
            this.showSelected = true;
            this.AddCodeButton = 'Cancel';
        }
    }

    HideButton() {
        this.showSelected = false;
    }

    addDomain() {
        this.DomainsData.push({ 'vDomainEmail': this.contract.Domains })
        this.contract.Domains = '';
    }
    deleteDomain(value) {
        let index = this.DomainsData.findIndex(order => order.vDomainEmail === value);
        this.DomainsData.splice(index, 1);
    }
    onFacilityDeSelect(deselectedFolder: { id: number, itemName: string }) {
        this.selectedfacility = [deselectedFolder]; // set the selected item as the deselected item 
        this.folderDropdown.closeDropdown(); // close the dropdown manually
    }
    onServicesDeSelect(deselectedFolder: { id: number, itemName: string }) {
        this.selectedservices = [deselectedFolder]; // set the selected item as the deselected item 
        this.folderDropdown.closeDropdown(); // close the dropdown manually
    }

    addServices() {
        if (this.AddServiceButton == 'Update') {
            let index = this.ServicesData.findIndex(order => order.vServiceName === this.contract.tempServices);
            this.ServicesData[index].vServiceName = this.contract.Services;
            this.ServicesData[index].vFacilityName = this.selectedfacility[0].itemName;
            this.ServicesData[index].vServiceEmail = this.contract.vServiceEmail;

            let index1 = this.ServicesTempData.findIndex(order => order.itemName === this.contract.tempServices);
            this.ServicesTempData[index1].itemName = this.contract.Services;
            this.selectedfacility = [];
            this.contract.Services = '';
            this.contract.vServiceEmail = '';
            this.contract.tempServices = '';
            this.AddServiceButton = 'Add'
        } else {
            this.ServicesTempData.push({ id: '', itemName: this.contract.Services })
            this.ServicesData.push({ 'vFacilityName': this.selectedfacility[0].itemName, 'vServiceName': this.contract.Services, 'vServiceEmail': this.contract.vServiceEmail, "UrgencyCode": [] })
            this.selectedfacility = [];
            this.contract.Services = '';
            this.contract.vServiceEmail = '';
        }

    }
    deleteServices(value) {
        let index = this.ServicesData.findIndex(order => order.vServiceName === value);
        this.ServicesData.splice(index, 1);
        //        new code added by jaimin 28/4/2018 *--------*
        let tempindex = this.ServicesTempData.findIndex(order => order.itemName === value);
        this.ServicesTempData.splice(tempindex, 1);
        //        *--------*
    }
    editServices(value) {
        this.AddServiceButton = 'Update'
        this.contract.Services = value.vServiceName;
        this.contract.tempServices = value.vServiceName;
        this.selectedfacility = [{ id: '', itemName: value.vFacilityName }]
        this.contract.vServiceEmail = value.vServiceEmail;
    }

    addFacility() {
        if (this.AddFacilityButton == 'Update') {
            console.log(this.contract.Facility)
            let index = this.FacilityData.findIndex(order => order.vFacilityName === this.contract.tempFacility);
            this.FacilityData[index].vFacilityName = this.contract.Facility;

            let index1 = this.FacilityTempData.findIndex(order => order.itemName === this.contract.tempFacility);
            this.FacilityTempData[index1].itemName = this.contract.Facility;

            this.contract.Facility = '';
            this.contract.tempFacility = '';
            this.AddFacilityButton = 'Add'
        } else {
            this.FacilityData.push({ 'vFacilityName': this.contract.Facility })
            this.FacilityTempData.push({ id: '', itemName: this.contract.Facility })
            this.contract.Facility = '';
        }
    }

    deleteFacility(value) {
        let index = this.FacilityData.findIndex(order => order.vFacilityName === value);
        this.FacilityData.splice(index, 1);
        //        new code added by jaimin 28/4/2018 *--------*
        let tempindex = this.FacilityTempData.findIndex(order => order.itemName === value);
        this.FacilityTempData.splice(tempindex, 1);
        //        *--------*
    }

    editFacility(value) {
        this.AddFacilityButton = 'Update'
        this.contract.Facility = value;
        this.contract.tempFacility = value;
    }

    // new code added by jaimin 28/4/2018 *--------*
    addUrgencyCode() {
        if (this.AddUrgencyButton == 'Update') {
            let index = this.tempUrgencyData.findIndex(order => order.vServiceName === this.selectedservices[0].itemName && order.vUrgencyCode === this.contract.vTempUrgencyCode)
            console.log(index);
            if (index == -1) {
                this.tempUrgencyData.push({
                    'vServiceName': this.selectedservices[0].itemName,
                    'vUrgencyCode': this.contract.vUrgencyCode,
                    'vRectificationTime': this.contract.vRectificationTime,
                    'vResponseTime': this.contract.vResponseTime,
                    'vUrgencyHelpText': this.contract.vUrgencyHelpText
                })
                this.deleteUrgencyCode(this.contract.tempServices, this.contract.vTempUrgencyCode);
            } else {
                this.tempUrgencyData[index].vServiceName = this.selectedservices[0].itemName;
                this.tempUrgencyData[index].vUrgencyCode = this.contract.vUrgencyCode;
                this.tempUrgencyData[index].vRectificationTime = this.contract.vRectificationTime;
                this.tempUrgencyData[index].vResponseTime = this.contract.vResponseTime;
                this.tempUrgencyData[index].vUrgencyHelpText = this.contract.vUrgencyHelpText;
            }
        } else {
            this.tempUrgencyData.push({
                'vServiceName': this.selectedservices[0].itemName,
                'vUrgencyCode': this.contract.vUrgencyCode,
                'vRectificationTime': this.contract.vRectificationTime,
                'vResponseTime': this.contract.vResponseTime,
                'vUrgencyHelpText': this.contract.vUrgencyHelpText
            })

        }
        this.showSelected = false;
        this.AddCodeButton = 'Add New';
        this.AddUrgencyButton = 'Add';
        this.contract.vUrgencyCode = '';
        this.contract.vRectificationTime = '';
        this.contract.vResponseTime = '';
        this.contract.vTempUrgencyCode = '';
        this.contract.tempServices = '';
        this.contract.vUrgencyHelpText = '';
        this.selectedservices = [];
    }
    deleteUrgencyCode(value, urgencyCode) {
        let index = this.tempUrgencyData.findIndex(order => order.vServiceName === value && order.vUrgencyCode === urgencyCode)
        //        console.log(index);
        this.tempUrgencyData.splice(index, 1);
    }
    editUrgencyCode(value, data) {
        this.showSelected = true;
        this.AddCodeButton = 'Cancel';
        this.AddUrgencyButton = 'Update';
        this.contract.vUrgencyCode = data.vUrgencyCode;
        this.contract.vTempUrgencyCode = data.vUrgencyCode;
        this.contract.tempServices = value;
        this.contract.vRectificationTime = data.vRectificationTime;
        this.contract.vResponseTime = data.vResponseTime;
        this.contract.vUrgencyHelpText = data.vUrgencyHelpText;
        this.selectedservices = [];
        this.selectedservices.push({ id: '', itemName: value })
    }

    //    addUrgencyCode() {
    //        if (this.AddUrgencyButton == 'Update') {
    //            let index = this.ServicesData.findIndex(order => order.vServiceName === this.selectedservices[0].itemName);
    //            let index1 = this.ServicesData[index].UrgencyCode.findIndex(order => order.vUrgencyCode === this.contract.vTempUrgencyCode);
    //            if (index1 == '-1') {
    //                this.ServicesData[index].UrgencyCode.push(
    //                    {
    //                        'vUrgencyCode': this.contract.vUrgencyCode,
    //                        'vRectificationTime': this.contract.vRectificationTime,
    //                        'vResponseTime': this.contract.vResponseTime,
    //                        'vUrgencyHelpText': this.contract.vUrgencyHelpText
    //                    })
    //                this.deleteUrgencyCode(this.contract.tempServices, this.contract.vTempUrgencyCode);
    //            } else {
    //                this.ServicesData[index].UrgencyCode[index1].vUrgencyCode = this.contract.vUrgencyCode;
    //                this.ServicesData[index].UrgencyCode[index1].vRectificationTime = this.contract.vRectificationTime;
    //                this.ServicesData[index].UrgencyCode[index1].vResponseTime = this.contract.vResponseTime;
    //                this.ServicesData[index].UrgencyCode[index1].vUrgencyHelpText = this.contract.vUrgencyHelpText;
    //            }
    //        } else {
    //            let index = this.ServicesData.findIndex(order => order.vServiceName === this.selectedservices[0].itemName);
    //            this.ServicesData[index].UrgencyCode.push(
    //                {
    //                    'vUrgencyCode': this.contract.vUrgencyCode,
    //                    'vRectificationTime': this.contract.vRectificationTime,
    //                    'vResponseTime': this.contract.vResponseTime,
    //                    'vUrgencyHelpText': this.contract.vUrgencyHelpText
    //                })
    //
    //        }
    //        this.showSelected = false;
    //        this.AddCodeButton = 'Add New';
    //        this.AddUrgencyButton = 'Add';
    //        this.contract.vUrgencyCode = '';
    //        this.contract.vRectificationTime = '';
    //        this.contract.vResponseTime = '';
    //        this.contract.vTempUrgencyCode = '';
    //        this.contract.tempServices = '';
    //        this.contract.vUrgencyHelpText = '';
    //        this.selectedservices = [];
    //    }
    //    deleteUrgencyCode(value, urgencyCode) {
    //        let index = this.ServicesData.findIndex(order => order.vServiceName === value);
    //        let urgencyIndex = this.ServicesData[index].UrgencyCode.findIndex(order => order.vUrgencyCode === urgencyCode);
    //        this.ServicesData[index].UrgencyCode.splice(urgencyIndex, 1);
    //    }
    //    editUrgencyCode(value, data) {
    //        this.showSelected = true;
    //        this.AddCodeButton = 'Cancel';
    //        this.AddUrgencyButton = 'Update';
    //        this.contract.vUrgencyCode = data.vUrgencyCode;
    //        this.contract.vTempUrgencyCode = data.vUrgencyCode;
    //        this.contract.tempServices = value;
    //        this.contract.vRectificationTime = data.vRectificationTime;
    //        this.contract.vResponseTime = data.vResponseTime;
    //        this.contract.vUrgencyHelpText = data.vUrgencyHelpText;
    //        this.selectedservices.push({id: '', itemName: value})
    //    }

    //*--------
    private setCurrentPosition() {
        let me = this;
        if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition((position) => {
                this.latitude = position.coords.latitude;
                this.longitude = position.coords.longitude;
                this.zoom = 12;
                me.getGeoLocation(this.latitude, this.longitude);
            });

        }

    }
    mapClicked(event) {
        this.latitude = event.coords.lat;
        this.longitude = event.coords.lng;
        this.getGeoLocation(this.latitude, this.longitude);
    }
    getGeoLocation(lat, lng) {
        let me = this;
        this.mapsAPILoader.load().then(() => {
            if (navigator.geolocation) {
                let geocoder = new google.maps.Geocoder();
                var myLatlng = new google.maps.LatLng(lat, lng);
                geocoder.geocode({
                    'location': myLatlng
                }, function (results, status) {
                    if (status == google.maps.GeocoderStatus.OK) {
                        me.contract.vAddress = results[0].formatted_address;
                        //                        console.log(me.contract.vAddress);
                    } else {
                    }
                });
            }
        });
    }
    addContract() {
        // new code added by jaimin 28/4/2018 *--------*
        for (let j = 0; j < this.ServicesData.length; j++) {
            this.ServicesData[j].UrgencyCode = [];
        }
        for (let i = 0; i < this.tempUrgencyData.length; i++) {
            let index = this.ServicesData.findIndex(order => order.vServiceName === this.tempUrgencyData[i].vServiceName);
            this.ServicesData[index].UrgencyCode.push({
                'vUrgencyCode': this.tempUrgencyData[i].vUrgencyCode,
                'vRectificationTime': this.tempUrgencyData[i].vRectificationTime,
                'vResponseTime': this.tempUrgencyData[i].vResponseTime,
                'vUrgencyHelpText': this.tempUrgencyData[i].vUrgencyHelpText
            })
        }
        // *----------------*       
        if (this.buttonText == 'Submit') {
            let action = Action.ADD_CONTRACT;
            let fd = new FormData();
            fd.append("vContractNumber", this.contract.vContractNumber);
            fd.append("vContractName", this.contract.vContractName);
            fd.append("vLatitude", this.latitude);
            fd.append("vLongitude", this.longitude);
            fd.append("vAddress", this.contract.vAddress);
            fd.append("vContractLogo", this.contract.vContractLogo);
            fd.append("Facility", JSON.stringify(this.FacilityData));
            fd.append("Domains", JSON.stringify(this.DomainsData));
            fd.append("Services", JSON.stringify(this.ServicesData));
            fd.append("Permissions", JSON.stringify(this.Permissions));
            this.busy = this.appService.remontServiceCall(action, fd)
                .subscribe(data => {
                    if (data.type == true) {
                        this.toastr.success(data.message);
                        this.router.navigate(['/contracts']);
                    } else {
                        this.toastr.error(data.message);
                    }
                }, error => {
                });
        } else {
            let action = Action.UPDATE_CONTRACT;
            let fd = new FormData();
            fd.append("iContractID", this.contract.id);
            fd.append("vContractNumber", this.contract.vContractNumber);
            fd.append("vContractName", this.contract.vContractName);
            fd.append("vLatitude", this.latitude);
            fd.append("vLongitude", this.longitude);
            fd.append("vAddress", this.contract.vAddress);
            fd.append("vContractLogo", this.contract.vContractLogo);
            fd.append("Facility", JSON.stringify(this.FacilityData));
            fd.append("Domains", JSON.stringify(this.DomainsData));
            fd.append("Services", JSON.stringify(this.ServicesData));
            fd.append("Permissions", JSON.stringify(this.Permissions));
            this.busy = this.appService.remontServiceCall(action, fd)
                .subscribe(data => {
                    if (data.type == true) {
                        this.toastr.success(data.message);
                        this.router.navigate(['/contracts']);
                    } else {
                        this.toastr.error(data.message);
                    }
                }, error => {
                });
        }
    }

    getOrganizations(callback?: any) {
        let action = Action.GET_ORGANIZATIONS;
        let fd = new FormData();
        this.busy = this.appService.remontServiceCall(action, fd)
            .subscribe(data => {
                if (data.type == true) {
                    //                    this.organizations = [];
                    let orgData = data.resultData;
                    for (let i = 0; i < orgData.length; i++) {
                        if (orgData[i].eStatus === 0) {
                            if (orgData[i].domains.length > 0) {
                                let p = this.organizations.findIndex(order => order._id === orgData[i].domains[0]._id);
                                if (p == -1) {
                                    this.organizations.push({ _id: orgData[i].domains[0]._id, vDomainEmail: orgData[i].domains[0].vDomainEmail, domains: [{ _id: orgData[i]._id, vOrganizationName: orgData[i].vOrganizationName }] })
                                } else {
                                    this.organizations[p].domains.push({ _id: orgData[i]._id, vOrganizationName: orgData[i].vOrganizationName });
                                }
                            }
                        }
                    }
                    //                    console.log(this.organizations)
                    //                    console.log(orgData[i])
                    //                    this.organizations = this.organizations.map(org => {
                    //                        org.domains = org.domains.filter(d => d.eStatus === 0);
                    //                        return org;
                    //                    });
                    callback && callback();
                } else {
                    this.toastr.error(data.message);
                }
            }, error => {
                this.toastr.error(error);
            });
    }

    getDomains(callback?: any) {
        let action = Action.GET_DOMAINS;
        let fd = new FormData();
        this.busy = this.appService.remontServiceCall(action, fd)
            .subscribe(data => {
                if (data.type == true) {
                    this.domains = data.resultData;
                } else {
                    this.toastr.error(data.message);
                }
                callback && callback();
            }, error => {
                this.toastr.error(error);
            });
    }

    onOrganizationDomainCheck(event, domain) {
        domain.isAssigned = event.target.checked;
        this.updatePermissions();
    }

    onOrganizationCheck(event, organization) {
        organization.isAssigned = event.target.checked;
        organization.domains.filter(d => {
            d.isAssigned = event.target.checked
        });
        this.updatePermissions();
    }

    onDomainCheck(event: any, domain: any) {
        domain.isAssigned = event.target.checked;
        if (event.target.checked) {
            this.organizations.filter(org => {
                org.domains.filter(d => {
                    d._id == domain._id && (d.isAssigned = true);
                })
            })
        }
        this.updatePermissions();
    }

    updatePermissions() {
        this.Permissions.Organizations = [];
        this.Permissions.Domains = [];
        this.organizations.filter(org => {
            let domains = [];
            org.domains.filter(domain => {
                domain.isAssigned && domains.push(domain._id);
            });
            domains.length && this.Permissions.Organizations.push({
                iOrganizationID: org._id,
                domains: domains
            });
            (domains.length === 1) && (org.isAssigned = true, org.isAssignedClass = 'oneselect');
            (domains.length !== 1) && org.isAssigned && (org.isAssigned = false);
            (org.domains.length === domains.length) && (org.isAssigned = true, org.isAssignedClass = '');
            (domains.length > 1 && domains.length < org.domains.length) && (org.isAssigned = true, org.isAssignedClass = 'oneselect');
        });
        this.domains.filter(d => d.isAssigned && this.Permissions.Domains.push(d._id));
    }

    getPermissions() {
        if (this.Permissions.Organizations && this.Permissions.Organizations.length) {
            this.Permissions.Organizations.filter(org => {
                let domains = [];
                let ref = this.organizations.find(_org => {
                    return _org._id === org.iOrganizationID;
                })
                if (ref) {
                    ref.domains.filter(dom => {
                        (org.domains.indexOf(dom._id) !== -1) && (dom.isAssigned = true) && (domains.push(dom._id));
                    });
                    (ref.domains.length === domains.length) && (ref.isAssigned = true);
                    (ref.domains.length !== domains.length) && ref.isAssigned && (ref.isAssigned = false);
                }
            })
        }
        if (this.Permissions.Domains) {
            this.domains.filter(dom => {
                (this.Permissions.Domains.indexOf(dom._id) !== -1) && (dom.isAssigned = true);
            });
        }
    }
    editCompanyLogoChange(event: any) {
        let reader = new FileReader();
        reader.onload = (e: any) => {
            this.contractLogo = e.target.result;
        }
        reader.readAsDataURL(event.target.files[0]);
    }
    deleteLogo(event: any) {
        event.preventDefault();
        this.contractLogo = '';
        this.contract.vContractLogo = '';

    }
    scroll_stop: any = '';
    onOpen() {
        if (this.scroll_stop == 'scroll_stop') {
            // this.mScrollbarService.update('#myElementId')
            // this.scrollbarOptions['mouseWheel'] = { enable: true }
            // this.mScrollbarService.initScrollbar('#myElementId', { axis: 'y' });
            this.scroll_stop = 'mCustomScrollbar';
        } else {
            // this.scrollbarOptions['mouseWheel'] = { enable: false }
            // this.mScrollbarService.disable('#myElementId');
            this.scroll_stop = 'scroll_stop';
        }
    }
    onResize(event) {
        this.innerWidth = event.target.innerWidth;
    }

}