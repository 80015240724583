import {Component} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {routerTransition} from '../router.animations';
import {AppService} from '../directive/app.service';
import {Action} from '../directive/app.constants';
@Component({
    selector: 'confirm',
    templateUrl: './confirm.html',
    styleUrls: ['./confirm.component.scss'],
    animations: [routerTransition()]
})

export class ConfirmComponent {
    tempId: any;
    message: any;
    title: any;
    constructor(public router: Router, private appService: AppService, private route: ActivatedRoute) {
        this.route.params.subscribe(params => {
            this.tempId = (params['id']); // (+) converts string 'id' to a number
        });
    }
    ngOnInit() {
        let action = Action.CONFIRM_EMAIL;
        let fd = new FormData();
        fd.append("iUserID", this.tempId);
        this.appService.remontServiceCall(action, fd)
            .subscribe(data => {
                if (data.type == true) {
                    this.message = data.message;
                    this.title = data.title;
                } else {
                    this.message = data.message;
                }
            }, error => {
            });
    }
}
