import { Component } from '@angular/core';
import { routerTransition } from '../router.animations';
import { AppService } from '../directive/app.service';
import { Action } from '../directive/app.constants';
import { Subscription } from 'rxjs';

@Component({
    selector: 'forgot',
    templateUrl: './forgot.html',
    styleUrls: ['./forgot.component.scss'],
    animations: [routerTransition()]
})

export class ForgotComponent {
    busy: Subscription;
    forgot = {
        vEmail: '',
        message: ''
    }
    public loading = false;
    constructor(private appService: AppService) {
    }
    onforgotPassword() {
        let action = Action.FORGOT_PASSWORD;
        let fd = new FormData();
        fd.append("vEmail", this.forgot.vEmail);
        this.busy = this.appService.remontServiceCall(action, fd)
            .subscribe(data => {
                if (data.type == true) {
                    this.forgot = {
                        vEmail: '',
                        message: data.message

                    }
                } else {
                    this.forgot.message = data.message
                }
            }, error => {
            });
    }
}