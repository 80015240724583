import { Component, ViewContainerRef } from '@angular/core';
import { Router } from '@angular/router';
import { routerTransition } from '../../router.animations';
import { MalihuScrollbarService } from 'ngx-malihu-scrollbar';
import { AppService } from '../../directive/app.service';
import { Action, AppConstants } from '../../directive/app.constants';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
// import {ToastsManager} from 'ng2-toastr/ng2-toastr';

@Component({
    selector: 'requests',
    templateUrl: './requests.html',
    styleUrls: ['./requests.component.scss'],
    animations: [routerTransition()]
})

export class RequestsComponent {
    busy: Subscription;
    public scrollbarOptions = { axis: 'y', theme: 'minimal-dark', scrollButtons: { enable: true } };
    requestData: Array<any> = [];
    requestType = {
        0: 'Contract',
        1: 'Domain',
        2: 'Organization'
    };
    constructor(public router: Router, private appService: AppService, public toastr: ToastrService, vcr: ViewContainerRef) {
        // this.toastr.setRootViewContainerRef(vcr)
    }
    ngOnInit() {
        let contractID = AppConstants.CONTRACT_ADMIN_CONTRACT;
        let action = Action.GET_REQUEST;
        let fd = new FormData();
        fd.append("iContractID", contractID);
        this.busy = this.appService.remontServiceCall(action, fd)
            .subscribe(data => {
                if (data.type == true){

                    let tempRequestData = data.resultData.filter((value) => {
                        return value.iUserID !== null
                    });
                    // console.log(tempRequestData)
                    this.requestData = tempRequestData;
                }
            }, error => { });
    }
    acceptRejectRequest(data, status) {
        let userId = JSON.parse(localStorage.getItem('userData'))._id;
        let requestTypeID;
        if (data.iRequestType == 0)
            requestTypeID = data.iContractID._id;
        if (data.iRequestType == 1)
            requestTypeID = data.iDomainID._id;
        if (data.iRequestType == 2)
            requestTypeID = data.iOrganizationID._id;

        let fd = new FormData();
        fd.append("iRequestID", data._id);
        fd.append("iApproveUserID", userId);
        fd.append("eStatus", status);
        //fd.append("iContractID", data.iContractID._id);
        fd.append("vDomainEmail", data.vDomain);
        fd.append("iUserID", data.iUserID._id);
        fd.append("iRequestType", data.iRequestType);
        fd.append("iRequestTypeID", requestTypeID);
        this.busy = this.appService.remontServiceCall(Action.ACCEPT_REJECT_REQUEST, fd)
            .subscribe(data => {
                this.ngOnInit();
                if (data.type == true) {
                    this.toastr.success(data.message);
                } else {
                    this.toastr.error(data.message);
                }
            }, error => {
            });
    }
    removeRequest(value) {
        let action = Action.REMOVE_REQUEST;
        let fd = new FormData();
        fd.append("iRequestID", value);
        this.busy = this.appService.remontServiceCall(action, fd)
            .subscribe(data => {
                this.ngOnInit();
                if (data.type == true) {
                    this.toastr.success(data.message);
                } else {
                    this.toastr.error(data.message);
                }
            }, error => {
            });
    }
}