import { Component, ViewContainerRef } from '@angular/core';
import { Router } from '@angular/router';
import { routerTransition } from '../../router.animations';
import { AppService } from '../../directive/app.service';
import { Action } from '../../directive/app.constants';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
// import {ToastsManager} from 'ng2-toastr/ng2-toastr';

@Component({
    selector: 'add-profile',
    templateUrl: './profile.html',
    styleUrls: ['./profile.component.scss'],
    animations: [routerTransition()]
})

export class ProfileComponent {
    busy: Subscription;
    adminuser = {
        vFirstName: '',
        vLastName: '',
        vEmail: '',
        vPhoneNumber: '',
        message: ''
    }

    userpasword = {
        vCurrentPassword: '',
        vNewPassword: '',
        vConfirmPassword: '',

    }
    constructor(public router: Router, private appService: AppService, public toastr: ToastrService, vcr: ViewContainerRef) {
        // this.toastr.setRootViewContainerRef(vcr);

        if (localStorage.getItem('userData')) {
            let data = JSON.parse(localStorage.getItem('userData'));
            this.adminuser.vFirstName = data.vFirstName;
            this.adminuser.vLastName = data.vLastName;
            this.adminuser.vEmail = data.vEmail;
            this.adminuser.vPhoneNumber = data.vPhoneNumber;

        }
    }

    submitForm() {
        let data = JSON.parse(localStorage.getItem('userData'));
        let action = Action.UPDATE_PROFILE;
        let fd = new FormData();
        fd.append("iUserID", data._id);
        fd.append("vFirstName", this.adminuser.vFirstName);
        fd.append("vLastName", this.adminuser.vLastName);
        fd.append("vEmail", this.adminuser.vEmail);
        fd.append("vPhoneNumber", this.adminuser.vPhoneNumber);


        this.busy = this.appService.remontServiceCall(action, fd)
            .subscribe(data => {
                if (data.type == true) {
                    let kittensFromLocalStorage = JSON.parse(localStorage.getItem("userData"));

                    kittensFromLocalStorage.vFirstName = this.adminuser.vFirstName;
                    kittensFromLocalStorage.vLastName = this.adminuser.vLastName;
                    kittensFromLocalStorage.vEmail = this.adminuser.vEmail;
                    kittensFromLocalStorage.vPhoneNumber = this.adminuser.vPhoneNumber;
                    localStorage.setItem("userData", JSON.stringify(kittensFromLocalStorage));
                    this.toastr.success(data.message);
                } else {
                    this.toastr.error(data.message);
                }
            }, error => {

            });

    }
    ChangePassword() {

        let userData = JSON.parse(localStorage.getItem('userData'));
        let action = Action.CHANGE_PASSWORD;
        let fd = new FormData();
        fd.append("iUserID", userData._id);
        fd.append("vPassword", this.userpasword.vCurrentPassword);
        fd.append("vNewPassword", this.userpasword.vNewPassword);
        this.busy = this.appService.remontServiceCall(action, fd)
            .subscribe(data => {
                if (data.type == true) {
                    this.toastr.success(data.message);
                    this.userpasword = {
                        vCurrentPassword: '',
                        vNewPassword: '',
                        vConfirmPassword: '',

                    }
                } else {
                    this.toastr.error(data.message);
                }
            }, error => {

            });

    }
}