import { Component, ViewContainerRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { routerTransition } from '../router.animations';
import { AppService } from '../directive/app.service';
import { Action } from '../directive/app.constants';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
// import {ToastsManager} from 'ng2-toastr/ng2-toastr';
@Component({
    selector: 'app-reset',
    templateUrl: './reset.html',
    styleUrls: ['./reset.component.scss'],
    animations: [routerTransition()]
})

export class ResetComponent {
    busy: Subscription;
    user = {
        confirmpassword: '',
        vPassword: '',
        message: ''
    }
    tempId: any;
    constructor(public router: Router, private route: ActivatedRoute, private appService: AppService, vcr: ViewContainerRef, public toastr: ToastrService) {
        // this.toastr.setRootViewContainerRef(vcr);
        this.route.params.subscribe(params => {
            this.tempId = (params['id']); // (+) converts string 'id' to a number
        });
    }
    onReset() {
        let action = Action.RESET_PASSWORD;
        let fd = new FormData();
        fd.append("iUserID", this.tempId);
        fd.append("vPassword", this.user.vPassword);
        this.busy = this.appService.remontServiceCall(action, fd)
            .subscribe(data => {
                if (data.type == true) {
                    this.user = {
                        confirmpassword: '',
                        vPassword: '',
                        message: data.message
                    }
                } else {
                    this.user.message = data.message;
                }
            }, error => {
            });
    }
}
