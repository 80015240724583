import { Component, ViewContainerRef } from '@angular/core';
import { Router } from '@angular/router';
import { routerTransition } from '../../router.animations';
import { AppService } from '../../directive/app.service';
import { Action, AppConstants } from '../../directive/app.constants';
import { ToastrService } from 'ngx-toastr';
// import {ToastsManager} from 'ng2-toastr/ng2-toastr';
@Component({
    selector: 'organizations',
    templateUrl: './organizations.html',
    styleUrls: ['./organizations.component.scss'],
    animations: [routerTransition()]
})

export class OrganizationsComponent {
    busy: any;
    organizationData = [];
    organizationName = '';
    organization: any;
    domainData = [];
    domainEmail = '';
    domain: any;
    eFalg: any = AppConstants.CONTRACT_ADMIN_EFLAG;
    showSelected: boolean = false;
    organizationDomains = [];
    organizationPerPage: any = '2';
    domainPerPage: any = '2';
    show_list: any[] = ['10', '20', '50', '100'];
    itemPerPage = this.show_list[0];
    search_text = '';
    domainsSetting: any;
    domainsSelectData: Array<any> = [];
    selectdomians: any;
    domainPage = 1;
    organizationPage = 1;
    timer: any = null;
    public scrollbarOptions = { axis: 'yx', theme: 'minimal-dark' };
    constructor(public router: Router, private appService: AppService, public toastr: ToastrService, vcr: ViewContainerRef) {
        // this.toastr.setRootViewContainerRef(vcr);
        this.domainsSetting = {
            singleSelection: true,
            text: "Select Domain",
            enableSearchFilter: false,
            classes: "facility_drop"
        };
    }

    ngOnInit() {
        this.refreshData();
    }

    search() {
        clearTimeout(this.timer);
        var me = this;
        this.timer = setTimeout(function () {
            me.domainPage = 1;
            me.organizationPage = 1;
            me.refreshData()
        }, 500);
        // this.refreshData();
    }

    getFormatDate(d: any) {
        let monthNames = [
            "January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ];
        var date = new Date(d);
        return date.getDate() + '-' + monthNames[date.getMonth()] + '-' + date.getFullYear();
    }

    clearData() {
        this.organization = null;
        this.domain = null;
        this.organizationDomains = [];
    }

    editOrganizationClick(organization: any) {
        this.clearData();
        this.search_text = '';
        this.refreshData()
        setTimeout(() => {
            this.showSelected = true;
            this.organization = { ...organization };
            //        this.organization = {...organization};
            this.organization = { ...organization };
            this.organizationDomains = this.domainData.map(domain => {
                domain = { ...domain };
                domain.isAssigned = !!this.organization.domains.find(_domain => {
                    return domain._id === _domain._id;
                });
                return domain
            });
        }, 500)


    }

    editDomainClick(domain: any) {
        this.search_text = '';
        this.refreshData()
        this.clearData();
        setTimeout(() => {
            this.showSelected = true;
            this.domain = { ...domain };

            for (var i = 0; i < this.organizationData.length; i++) {
                if (this.organizationData[i].domains.length == 0) {
                    this.organizationDomains.push(this.organizationData[i]);
                } else {
                    let p = this.organizationData[i].domains.findIndex(order => order._id === domain._id);
                    if (p != -1) {
                        this.organizationData[i].isAssigned = true
                        this.organizationDomains.push(this.organizationData[i]);
                    }
                }

            }
        }, 500)



    }

    hideSelected() {
        this.clearData();
        this.showSelected = false;
    }

    getOrganizations() {
        let action = Action.GET_ORGANIZATIONS;
        let fd = new FormData();
        fd.append("search_text", this.search_text);
        this.busy = this.appService.remontServiceCall(action, fd)
            .subscribe(data => {
                if (data.type == true) {
                    this.organizationData = data.resultData;
                    this.organizationData = this.organizationData.map(org => {
                        org.domains = org.domains.filter(d => d.eStatus === 0);
                        return org;
                    });
                } else {
                    this.toastr.error(data.message, 'Oops!');
                }
            }, error => {
                this.toastr.error(error, 'Oops!');
            });
    }

    getDomains() {
        let action = Action.GET_DOMAINS;
        let fd = new FormData();
        fd.append("search_text", this.search_text);
        this.busy = this.appService.remontServiceCall(action, fd)
            .subscribe(data => {
                if (data.type == true) {
                    this.domainData = data.resultData;
                    for (let l = 0; l < data.resultData.length; l++) {
                        this.domainsSelectData.push({ id: data.resultData[l]._id, itemName: data.resultData[l].vDomainEmail })
                    }
                } else {
                    this.toastr.error(data.message, 'Oops!');
                }
            }, error => {
                this.toastr.error(error, 'Oops!');
            });
    }

    addOrganization() {
        console.log(this.selectdomians)
        let action = Action.ADD_ORGANIZATION;
        let fd = new FormData();
        fd.append("vOrganizationName", this.organizationName);
        fd.append("vDomainID", this.selectdomians[0].id);
        this.busy = this.appService.remontServiceCall(action, fd)
            .subscribe(data => {
                if (data.type == true) {
                    this.organizationName = '';
                    this.selectdomians = [];
                    this.toastr.success(data.message);
                    this.refreshData();
                } else {
                    this.toastr.error(data.message);
                }
            }, error => {
                this.toastr.error(error);
            });
    }

    addDomain() {
        let action = Action.ADD_DOMAIN;
        let fd = new FormData();
        fd.append("vDomainEmail", this.domainEmail);
        this.busy = this.appService.remontServiceCall(action, fd)
            .subscribe(data => {
                if (data.type == true) {
                    this.domainEmail = '';
                    this.toastr.success(data.message);
                    this.refreshData();
                } else {
                    this.toastr.error(data.message);
                }
            }, error => {
                this.toastr.error(error);
            });
    }

    deleteOrganization(iOrganizationID: any) {
        let action = Action.DELETE_ORGANIZATION;
        let fd = new FormData();
        fd.append("iOrganizationID", iOrganizationID);
        this.busy = this.appService.remontServiceCall(action, fd)
            .subscribe(data => {
                if (data.type == true) {
                    this.toastr.success(data.message);
                    this.refreshData();
                    this.showSelected = false;
                } else {
                    this.toastr.error(data.message);
                }
            }, error => {
                this.toastr.error(error);
            });
    }

    deleteDomain(iDomainID: any) {
        let action = Action.DELETE_DOMAIN;
        let fd = new FormData();
        fd.append("iDomainID", iDomainID);
        this.busy = this.appService.remontServiceCall(action, fd)
            .subscribe(data => {
                if (data.type == true) {
                    this.toastr.success(data.message);
                    this.refreshData();
                    this.showSelected = false;
                } else {
                    this.toastr.error(data.message);
                }
            }, error => {
                this.toastr.error(error);
            });
    }

    editOrganization() {
        let action = Action.UPDATE_ORGANIZATION;
        let fd = new FormData();
        fd.append("iOrganizationID", this.organization._id);
        fd.append("vOrganizationName", this.organization.vOrganizationName);
        this.busy = this.appService.remontServiceCall(action, fd)
            .subscribe(data => {
                if (data.type == true) {
                    this.toastr.success(data.message);
                    this.refreshData();
                    this.showSelected = false;
                } else {
                    this.toastr.error(data.message);
                }
            }, error => {
                this.toastr.error(error);
            });
    }

    editDomain() {
        let action = Action.UPDATE_DOMAIN;
        let fd = new FormData();
        fd.append("iDomainID", this.domain._id);
        fd.append("vDomainEmail", this.domain.vDomainEmail);
        this.busy = this.appService.remontServiceCall(action, fd)
            .subscribe(data => {
                if (data.type == true) {
                    this.toastr.success(data.message);
                    this.refreshData();
                    this.showSelected = false;
                } else {
                    this.toastr.error(data.message);
                }
            }, error => {
                this.toastr.error(error);
            });
    }

    addOrganizationDomain() {
        let action = Action.ADD_ORGANIZATION_DOMAIN;
        let fd = new FormData();
        fd.append("iOrganizationID", this.organization._id);
        fd.append("vDomainEmail", this.domainEmail);
        this.busy = this.appService.remontServiceCall(action, fd)
            .subscribe(data => {
                if (data.type == true) {
                    this.toastr.success(data.message);
                    this.refreshData();
                } else {
                    this.toastr.error(data.message);
                }
            }, error => {
                this.toastr.error(error);
            });
    }

    assignOrganizationDomains() {
        let domains = [];
        this.organizationDomains.map(domain => {
            domain.isAssigned && domains.push(domain._id);
        });
        let action = Action.UPDATE_ORGANIZATION;
        let fd = new FormData();
        fd.append("iOrganizationID", JSON.stringify(domains));
        fd.append("domains", this.domain._id);

        this.busy = this.appService.remontServiceCall(action, fd)
            .subscribe(data => {
                if (data.type == true) {
                    this.toastr.success(data.message);
                    this.refreshData();
                    this.showSelected = false;
                } else {
                    this.toastr.error(data.message);
                }
            }, error => {
                this.toastr.error(error);
            });
    }

    refreshData() {
        this.getDomains();
        this.getOrganizations();
    }
    outSideClick() {
        console.log('jaimin')
        this.hideSelected()
    }
    activeClass: any = '';
    onClickTab(eFlag) {
        console.log(eFlag)
        this.activeClass = eFlag;
    }
}
