import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { routerTransition } from '../router.animations';
import { AppService } from '../directive/app.service';
import { Action } from '../directive/app.constants';
import { Subscription } from 'rxjs';
@Component({
    selector: 'login',
    templateUrl: './login.html',
    styleUrls: ['./login.component.scss'],
    animations: [routerTransition()]
})

export class LoginComponent {
    busy: Subscription;
    user = {
        vEmail: '',
        vPassword: '',
        message: ''
    }
    constructor(public router: Router, private appService: AppService) {
    }
    onLoggedin() {
        let action = Action.LOGIN;
        let fd = new FormData();
        fd.append("vEmail", this.user.vEmail);
        fd.append("vPassword", this.user.vPassword);
        fd.append("flag", '1');
        this.busy = this.appService.remontServiceCall(action, fd)
            .subscribe(data => {
                if (data.type == true) {
                    localStorage.setItem('userData', JSON.stringify(data.resultData));
                    console.log("userData ==>", data.resultData);

                    this.router.navigate(['/organisation']);
                } else {
                    this.user.message = data.message;
                }
            }, error => {
            });
    }
}
