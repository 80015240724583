export class AppConstants {
    // public static API_URL = "http://localhost:8000/";
    // public static API_URL = 'https://www.helpdesk.p-2-g.co.uk/';
    public static API_URL = 'https://pfi-new-api-d530c1978e80.herokuapp.com/';
    public static CONTRACT_ADMIN_EFLAG = '';
    public static CONTRACT_ADMIN_CONTRACT = '';
    public static TEMP_CONTRACT;
    public static TEMP_USER;
    public static TEMP_GENERAL_USER = '';
    public static TEMP_GENERAL_USER_NAME = '';
    public static HISTROY_PAGE;
    public static ClOUD_NAME = 'zudu';
    public static PRESENT_NAME = 'tscj57fi';

}

export class Action {
    public static LOGIN = 'user/login/'
    public static GET_CONTRACTLIST = 'get/contractlist/'
    public static GET_ISSUE_COUNTS = 'get/issueCounts/'
    public static ADD_CONTRACT = 'add/contract/'
    public static UPDATE_CONTRACT = 'update/contract/'
    public static DELETE_CONTRACT = 'delete/contract/'


    public static GET_ADMINS_USER = 'get/adminuser/'
    public static ACTIVE_ADMIN_USER = 'active/adminuser/'
    public static DELETE_ADMIN_USER = 'delete/adminuser/'
    public static GET_ADMIN_CONTRACT = 'get/usercontract/'
    public static ADD_ADMIN_USER = 'add/adminuser/'
    public static UPDATE_ADMIN_USER = 'update/adminuser/'

    public static GET_ISSUES = 'get/issues/'
    public static UPDATE_STATUS = 'update/status/'
    public static GET_CONTRACT_ADMIN = 'get/contractadmin/'
    public static DELETE_ISSUE = 'delete/issue/'
    public static GET_ISSUE_DETAILS = 'get/issue/details/'

    public static UPDATE_PROFILE = 'update/profile/'
    public static CHANGE_PASSWORD = 'change/password/'
    public static RESET_PASSWORD = 'reset/password/'
    public static FORGOT_PASSWORD = 'forgot/password/'
    public static CONFIRM_EMAIL = 'confirm/email/'

    public static GET_REQUEST = 'get/request/'
    public static ACCEPT_REJECT_REQUEST = 'accept/request/'
    public static REMOVE_REQUEST = 'remove/request'

    public static GET_ORGANIZATIONS = 'get/organizations/'
    public static ADD_ORGANIZATION = 'add/organization/'
    public static DELETE_ORGANIZATION = 'delete/organization/'
    public static UPDATE_ORGANIZATION = 'update/organization/'
    public static ASSIGN_ORGANIZATION_DOMAIN = 'assign/organizationdomain/'
    public static ADD_ORGANIZATION_DOMAIN = 'add/organizationdomain/'

    public static GET_DOMAINS = 'get/domains/'
    public static ADD_DOMAIN = 'add/domain/'
    public static DELETE_DOMAIN = 'delete/domain/'
    public static UPDATE_DOMAIN = 'update/domain/'

    public static GET_CONTRACT = 'get/admincontract/'
    public static GET_CONTRACT_COUNT = 'get/contractcount/'
    public static UPDATE_LOGIN_PERMISSION = 'update/loginpermission'

    public static GET_HELPS = 'get/helps/'
    public static ADD_HEADER = 'add/header/'
    public static UPDATE_HEADER = 'update/header/'
    public static DELETE_HEADER = 'delete/header/'
    public static ADD_HEADER_LINK = 'add/link/'
    public static UPDATE_HEADER_LINK = 'update/link/'
    public static DELETE_HEADER_LINK = 'delete/link/'
    public static UPDATE_HEADER_LIST = 'update/headerList'
}
