import { Component, ViewContainerRef } from '@angular/core';
import { Router } from '@angular/router';
import { routerTransition } from '../../router.animations';
import { MalihuScrollbarService } from 'ngx-malihu-scrollbar';
import { AppService } from '../../directive/app.service';
import { Action, AppConstants } from '../../directive/app.constants';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
    selector: 'help',
    templateUrl: './help.html',
    styleUrls: ['./help.component.scss'],
    animations: [routerTransition()]
})

export class HelpComponent {
    busy: Subscription;
    public scrollbarOptions = { axis: 'y', theme: 'minimal-dark', scrollButtons: { enable: true } };
    helpData: Array<any> = [];

    // Properties to control modal visibility
    showAddHeaderModal = false;
    showUpdateHeaderModal = false;
    showAddLinkModal = false;
    showUpdateLinkModal = false;

    selectedHeader: any;
    selectedLink: any;

    addHeaderForm: FormGroup;
    updateHeaderForm: FormGroup;
    addLinkForm: FormGroup;
    updateLinkForm: FormGroup;

    constructor(public router: Router, private appService: AppService, public toastr: ToastrService, vcr: ViewContainerRef, private fb: FormBuilder) {
        this.addHeaderForm = this.fb.group({
            headerName: ['', Validators.required]
        });

        this.updateHeaderForm = this.fb.group({
            headerName: ['', Validators.required]
        });

        this.addLinkForm = this.fb.group({
            linkTitle: ['', Validators.required],
            linkUrl: ['', [Validators.required, Validators.pattern(/^(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/)]]
        });

        this.updateLinkForm = this.fb.group({
            linkTitle: ['', Validators.required],
            linkUrl: ['', [Validators.required, Validators.pattern(/^(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/)]]
        });
    }

    ngOnInit() {
        this.getHelpData();
    }

    getHelpData() {
        let action = Action.GET_HELPS;
        this.busy = this.appService.remontServiceGetCall(action)
            .subscribe(data => {
                // console.log(tempRequestData)
                this.helpData = data.data;
            }, error => { });
    }

    // In your component class
    toggleLinkList(header) {
        header.showLinks = !header.showLinks;
    }

    // Method to open modals
    openAddHeaderModal() {
        this.showAddHeaderModal = true;
        this.addHeaderForm.reset()
    }

    openUpdateHeaderModal(header) {
        this.showUpdateHeaderModal = true;
        this.selectedHeader = header._id

        // Set values in the form
        this.updateHeaderForm.patchValue({
            headerName: header.name
        });
    }

    openAddLinkModal(header) {
        this.showAddLinkModal = true;
        this.selectedHeader = header._id
        this.addLinkForm.reset()
    }

    openUpdateLinkModal(header, link) {
        this.showUpdateLinkModal = true;
        this.selectedHeader = header._id
        this.selectedLink = link._id
        this.updateLinkForm.patchValue({
            linkTitle: link.title,
            linkUrl: link.url,
        })
    }

    // Method to close modals
    closeModal(modalType: string) {
        switch (modalType) {
            case 'addHeader':
                this.showAddHeaderModal = false;
                break;
            case 'updateHeader':
                this.showUpdateHeaderModal = false;
                break;
            case 'addLink':
                this.showAddLinkModal = false;
                break;
            case 'updateLink':
                this.showUpdateLinkModal = false;
                break;
        }
    }

    addHeader() {
        let action = Action.ADD_HEADER;
        let fd = new FormData();
        const value = this.addHeaderForm.value
        fd.append("name", value.headerName);
        this.busy = this.appService.remontServiceHelpCall(action, fd)
            .subscribe(data => {
                this.ngOnInit();
                this.toastr.success(data.message);
            }, error => {
                const message = JSON.parse(error._body).message
                this.toastr.error(message);
            });
        this.closeModal('addHeader');
    }

    updateHeader() {
        let action = Action.UPDATE_HEADER;
        let fd = new FormData();
        const value = this.updateHeaderForm.value
        fd.append("headerId", this.selectedHeader);
        fd.append("name", value.headerName);
        this.busy = this.appService.remontServiceHelpCall(action, fd)
            .subscribe(data => {
                this.ngOnInit();
                this.toastr.success(data.message);
            }, error => {
                const message = JSON.parse(error._body).message
                this.toastr.error(message);
            });
        this.closeModal('updateHeader');
        this.selectedHeader = null
    }

    deleteHeader(headerId: string) {
        let action = Action.DELETE_HEADER;
        let fd = new FormData();
        fd.append("headerId", headerId);
        this.busy = this.appService.remontServiceHelpCall(action, fd)
            .subscribe(data => {
                this.ngOnInit();
                this.toastr.success(data.message);
            }, error => {
                const message = JSON.parse(error._body).message
                this.toastr.error(message);
            });
    }

    addHeaderLink() {
        let action = Action.ADD_HEADER_LINK;
        let fd = new FormData();
        const value = this.addLinkForm.value
        fd.append("headerId", this.selectedHeader);
        fd.append("title", value.linkTitle);
        fd.append("url", value.linkUrl);
        this.busy = this.appService.remontServiceHelpCall(action, fd)
            .subscribe(data => {
                this.ngOnInit();
                this.toastr.success(data.message);
            }, error => {
                const message = JSON.parse(error._body).message
                this.toastr.error(message);
            });
        this.closeModal('addLink');
    }

    updateHeaderLink() {
        let action = Action.UPDATE_HEADER_LINK;
        let fd = new FormData();
        const value = this.updateLinkForm.value
        fd.append("headerId", this.selectedHeader);
        fd.append("linkId", this.selectedLink);
        fd.append("title", value.linkTitle);
        fd.append("url", value.linkUrl);
        this.busy = this.appService.remontServiceHelpCall(action, fd)
            .subscribe(data => {
                this.ngOnInit();
                this.toastr.success(data.message);
            }, error => {
                const message = JSON.parse(error._body).message
                this.toastr.error(message);
            });
        this.closeModal('updateLink');
    }

    deleteHeaderLink(headerId: string, linkId: string) {
        let action = Action.DELETE_HEADER_LINK;
        let fd = new FormData();
        fd.append("headerId", headerId);
        fd.append("linkId", linkId);
        this.busy = this.appService.remontServiceHelpCall(action, fd)
            .subscribe(data => {
                this.ngOnInit();
                this.toastr.success(data.message);
            }, error => {
                console.log('delete error: ', error)
                const message = JSON.parse(error._body).message
                this.toastr.error(message);
            });
    }

    drop(event: CdkDragDrop<any[]>, header: any): void {
        moveItemInArray(header.links, event.previousIndex, event.currentIndex);
        
        if(header && header.links && header.links.length>1){
        let action = Action.UPDATE_HEADER;
        let fd: any = new FormData();
        fd.append("headerId", header._id);
        fd.append("links", JSON.stringify(header.links.map(link => {

            let obj = {
                _id: link._id,
                title: link.title,
                url: link.url
            }
            return obj;
        })));
        // Logging FormData fields (iterate over formData's keys)
        for (const key of fd.keys()) {
            console.log(`${key}: ${fd.get(key)}`);
        }
        this.appService.remontServiceHelpCall(action, fd)
            .subscribe(data => {
            }, error => {
                const message = JSON.parse(error._body).message;
            });
        }
    }

    // New drop method for headers
    dropHeader(event: CdkDragDrop<any[]>): void {
        moveItemInArray(this.helpData, event.previousIndex, event.currentIndex);

        this.helpData = this.helpData.map((header, index) => {
            return {
                ...header,
                index
            }
        })

        let action = Action.UPDATE_HEADER_LIST;
        let fd: any = new FormData();
        fd.append("headerList", JSON.stringify(this.helpData))
        this.appService.remontServiceHelpCall(action, fd)
            .subscribe(data => {
            }, error => {
                const message = JSON.parse(error._body).message;
            });
    }
}