import { Injectable } from '@angular/core';
// import { HttpClient } from '@angular/common/http';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { Observable } from 'rxjs';
// import { map, catchError } from "rxjs/operators";
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
// import 'rxjs/add/operator/toPromise';
import { AppConstants } from './app.constants';

@Injectable()
export class AppService {
    url = AppConstants.API_URL;
    constructor(private http: Http) { }
    remontServiceCall(action, requestJSON) {
        return this.http.post(this.url + action, requestJSON)
            .map(this.extractData)
            .catch(this.handleErrorObservable)
    }
    remontServiceHelpCall(action, requestJSON) {
        return this.http.post(this.url + action, requestJSON)
            .map(this.extractData)
            .catch(this.handleErrorPromise)
    }
    remontServiceGetCall(action) {
        return this.http.get(this.url + action)
            .map(this.extractData)
            .catch(this.handleErrorObservable)
    }
    private extractData(res: Response) {
        let body = res.json();
        return body
    }
    private handleErrorObservable(error: Response | any) {
        return Observable.throw(error.message || error);
    }
    private handleErrorPromise(error: Response | any) {
        console.error(error.message || error);
        return Promise.reject(error.message || error);
    }
} 