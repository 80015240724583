import {animate, state, style, transition, trigger, query} from '@angular/animations';

export function routerTransition() {
    return fadeAnimation();
}
export function fadeAnimation() {

    return trigger('fadeAnimation', [
        state('void', style({})),
        state('*', style({})),
        transition(':leave', [
            style({opacity: 1}),
            animate('0.7s', style({opacity: 0}))
        ]),
        transition(':enter', [
            style({opacity: 0}),
            animate('0.8s', style({opacity: 1}))
        ]),

    ]);
}
export function slideToRight() {
    return trigger('slideToRight', [
        state('void', style({})),
        state('*', style({})),
        transition(':enter', [
            style({transform: 'translateX(-100%)'}),
            animate('10s ease-in-out', style({transform: 'translateX(0%)'}))
        ]),
        transition(':leave', [
            style({transform: 'translateX(0%)'}),
            animate('10s ease-in-out', style({transform: 'translateX(100%)'}))
        ])
    ]);
}

export function slideToLeft() {
    return trigger('slideToLeft', [
        state('void', style({})),
        state('*', style({})),
        transition(':enter', [
            style({transform: 'translateX(100%)'}),
            animate('0.5s ease-in-out', style({transform: 'translateX(0%)'}))
        ]),
        transition(':leave', [
            style({transform: 'translateX(0%)'}),
            animate('0.5s ease-in-out', style({transform: 'translateX(-100%)'}))
        ])
    ]);
}

export function slideToBottom() {
    return trigger('routerTransition', [
        state('void', style({})),
        state('*', style({})),
        transition(':enter', [
            style({transform: 'translateY(-100%)'}),
            animate('0.5s ease-in-out', style({transform: 'translateY(0%)'}))
        ]),
        transition(':leave', [
            style({transform: 'translateY(0%)'}),
            animate('0.5s ease-in-out', style({transform: 'translateY(100%)'}))
        ])
    ]);
}

export function slideToTop() {
    return trigger('routerTransition', [
        state('void', style({})),
        state('*', style({})),
        transition(':enter', [
            style({transform: 'translateY(100%)'}),
            animate('0.5s ease-in-out', style({transform: 'translateY(0%)'}))
        ]),
        transition(':leave', [
            style({transform: 'translateY(0%)'}),
            animate('0.5s ease-in-out', style({transform: 'translateY(-100%)'}))
        ])
    ]);
}
