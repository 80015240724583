import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AppConstants } from '../directive/app.constants';


@Component({
    selector: 'layout-root',
    templateUrl: './layout.component.html',
    styleUrls: ['./layout.component.css']
})
export class LayoutComponent {
    title = 'app';
    eFalg: any = '';
    public isCollapsed = true;
    constructor(private router: Router, private route: ActivatedRoute) {
        //        this.router.navigate(['/contracts']);
        if (JSON.parse(localStorage.getItem('userData'))) {
            AppConstants.CONTRACT_ADMIN_EFLAG = JSON.parse(localStorage.getItem('userData')).eFlag;
            if (AppConstants.CONTRACT_ADMIN_EFLAG == '1' || AppConstants.CONTRACT_ADMIN_EFLAG == '2') {
                let data = JSON.parse(localStorage.getItem('userData')).Contracts;
                let contract = [];
                for (let j = 0; j < data.length; j++) {
                    contract.push(data[j].iContractID);
                }
                AppConstants.CONTRACT_ADMIN_CONTRACT = contract.join(',');
                this.eFalg = AppConstants.CONTRACT_ADMIN_EFLAG;
            }
        }
    }
    ngOnInit() {
        if (this.router.url === '/') {
            if (localStorage.getItem('userData')) {
                this.router.navigate(['/organisation']);
                return true;
            }
            this.router.navigate(['/login']);
            return false;
        } else {
            if (localStorage.getItem('userData')) {
                return true;
            }
            this.router.navigate(['/login']);
            return false;
        }
    }
    logOut() {
        localStorage.clear();
        AppConstants.CONTRACT_ADMIN_EFLAG = ''
        AppConstants.CONTRACT_ADMIN_CONTRACT = ''
    }
}
