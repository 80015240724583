
import { Component, ViewContainerRef, ChangeDetectorRef  } from '@angular/core';
import { Router } from '@angular/router';
import { routerTransition } from '../../router.animations';
import { MalihuScrollbarService } from 'ngx-malihu-scrollbar';
import { AppService } from '../../directive/app.service';
import { Action, AppConstants } from '../../directive/app.constants';
import { NgbDateParserFormatter, NgbDate, NgbDatepickerConfig } from "@ng-bootstrap/ng-bootstrap";
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { NgbDateCustomParserFormatter } from '../../formatter';

// import {ToastsManager} from 'ng2-toastr/ng2-toastr';
@Component({
    selector: 'contracts',
    templateUrl: './contracts.html',
    styleUrls: ['./contracts.component.scss'],
    animations: [routerTransition()],
    providers: [
        { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter }
    ]
})

export class ContractsComponent {
    busy: Subscription;
    title = 'app';
    search_text: string = '';
    show_contract: any;
    public scrollbarOptions = { axis: 'y', theme: 'minimal-dark', scrollButtons: { enable: true } };
    show_list: any[] = ['10', '20', '50', '100'];
    page: any = 1;
    showSelected: boolean;
    contractData: Array<any> = [];
    ContractDetail: any;
    eFalg: any = AppConstants.CONTRACT_ADMIN_EFLAG;
    organizations: Array<any> = [];
    totalItem: number;
    timer: any = null;
    start_date: any = '';
    end_date: any = '';
    loadingCounts: boolean;
    constructor(public router: Router, private appService: AppService, private cdr: ChangeDetectorRef, public toastr: ToastrService, vcr: ViewContainerRef, private config: NgbDatepickerConfig) {
        // this.toastr.setRootViewContainerRef(vcr);
        this.loadingCounts = false;
        this.showSelected = false;
        this.show_contract = '10';
        if (AppConstants.HISTROY_PAGE) {
            this.show_contract = AppConstants.HISTROY_PAGE.show_contract;
            this.page = AppConstants.HISTROY_PAGE.page;
            this.search_text = AppConstants.HISTROY_PAGE.search_text;
            AppConstants.HISTROY_PAGE = null;
        }
    }

    getIssueCounts(contractIds: string[]): void {
        this.loadingCounts = true; // Set loading state to true

        let fd = new FormData();
        fd.append('contractIds', contractIds.join(','));
    
        this.appService.remontServiceCall(Action.GET_ISSUE_COUNTS, fd).subscribe(data => {
            if (data.type) {
                const issueCounts = data.resultData;
                // Merge issueCounts into contractData
                this.contractData.forEach(contract => 
                    {
                    const issueData = issueCounts.find(issue => issue._id === contract._id._id);
                    if (issueData) {
                        contract.totalcount = issueData.totalcount;
                        contract.pendingcount = issueData.pendingcount;
                    } else {
                        contract.totalcount = 0;
                        contract.pendingcount = 0;
                    }
                });
                this.cdr.detectChanges(); // Manually trigger change detection
            } else {
                // Handle error case if needed
            }
            this.loadingCounts = false; // Set loading state to false

        }, error => {
            console.error('Error fetching issue counts:', error);
            this.loadingCounts = false; // Set loading state to false

        });
    }

    
    ngOnInit() {
        var startDate = '';
        var endDate = '';
        if (this.start_date != '') {
            startDate = this.start_date.year + '-' + this.start_date.month + '-' + this.start_date.day;
        }
        if (this.end_date != '') {
            endDate = this.end_date.year + '-' + this.end_date.month + '-' + this.end_date.day;
        }
        var userData = JSON.parse(localStorage.getItem('userData'));
        let action = Action.GET_CONTRACTLIST;
    
        let fd = new FormData();
        fd.append("search_text", this.search_text);
        fd.append("start_date", startDate);
        fd.append("end_date", endDate);
        fd.append("iContractID", AppConstants.CONTRACT_ADMIN_CONTRACT);
        fd.append("iUserID", userData._id);
        fd.append("eFlag", AppConstants.CONTRACT_ADMIN_EFLAG);
        fd.append("limit", this.show_contract);
        fd.append("skip", this.page);
        this.busy = this.appService.remontServiceCall(action, fd)
            .subscribe(data => {
                if (data.type == true) {
                    this.contractData = data.resultData;
                    this.totalItem = data.totalItem;
    
                    const contractIds = this.contractData.map(contract => contract._id._id);
                    this.getIssueCounts(contractIds);
    
                    this.getOrganizations(() => {
                        this.contractData.filter(cont => {
                            
                            cont._id.PermissionDetail = {}
                            if (cont._id.Permissions && cont._id.Permissions.Organizations) {
                                cont._id.PermissionDetail.Organizations = cont._id.Permissions.Organizations.map(per => {
                                    var ref = this.organizations.find(org => {
                                        return org._id == per.iOrganizationID;
                                    });
                                    if (ref) {
                                        ref.domains = ref.domains.filter(dom => {
                                            return per.domains.indexOf(dom._id) != -1;
                                        });
                                    }
                                    return ref;
                                });
                            }
                        });
                    });
                } else {
                    // Handle error case if needed
                }
            }, error => {
                console.error('Error fetching contract list:', error);
            });
    }
    
    pageChange(event) {
        this.page = event;
        this.ngOnInit();
    }
    getOrganizations(callback?: any) {
        let action = Action.GET_ORGANIZATIONS;
        let fd = new FormData();
        this.busy = this.appService.remontServiceCall(action, fd)
            .subscribe(data => {
                if (data.type == true) {
                    this.organizations = data.resultData;
                    //                    this.organizations = this.organizations.map(org => {
                    //                        org.domains = org.domains.filter(d => d.eStatus === 0);
                    //                        return org;
                    //                    });
                    callback && callback();
                }
            }, error => { });
    }
    searchContract() {
        clearTimeout(this.timer);
        var me = this;
        this.timer = setTimeout(function () {
            me.page = 1;
            me.ngOnInit()
        }, 500);
        // this.ngOnInit();
    }
    ShowButton(data) {
        console.log(data);
        setTimeout(() => {
            this.showSelected = true;
            this.ContractDetail = data;
        }, 200)

    }
    HideButton() {
        this.showSelected = false;
    }
    getFacility(facility) {

        let data = [];
        if (facility) {
            for (let j = 0; j < facility.length; j++) {

                data.push(facility[j].vFacilityName);
            }
            let facilityString = data.join(',');

            return facilityString;
        }
    }
    getServices(Services) {

        let data = [];
        if (Services) {
            for (let j = 0; j < Services.length; j++) {

                data.push(Services[j].vServiceName);
            }
            let serviceNameString = data.join(',');

            return serviceNameString;
        }
    }
    getFormatDate(dateDate) {
        let monthNames = ["January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ];
        var date = new Date(dateDate);
        return date.getDate() + '-' + monthNames[date.getMonth()] + '-' + date.getFullYear();

    }
    deleteContract(value) {
        let action = Action.DELETE_CONTRACT;
        let fd = new FormData();
        fd.append("iContractID", value);
        this.busy = this.appService.remontServiceCall(action, fd)
            .subscribe(data => {
                if (data.type == true) {
                    this.toastr.success(data.message);
                    this.ngOnInit();
                } else {
                }
            }, error => {
            });
    }
    editContract(param) {
        localStorage.setItem('contractData', JSON.stringify(param));
        this.router.navigate(['/contracts/add']);
    }
    addContract() {
        localStorage.setItem('contractData', '');
        this.router.navigate(['/contracts/add']);
    }
    showIssues(value) {
        let param = { 'search_text': this.search_text, 'show_contract': this.show_contract, 'page': this.page }
        AppConstants.HISTROY_PAGE = param;
        AppConstants.TEMP_CONTRACT = value;
    }
    outSideClick() {
        console.log('jaimin')
        this.HideButton()
    }
    chnageDate() {
        setTimeout(() => {
            this.ngOnInit()
        }, 500)
    }
    resetDateFilter() {
        this.start_date = '';
        this.end_date = '';
        this.ngOnInit();
    }
    onDateSelection() {
        this.config.minDate = this.start_date;

    }
    isOpen() {
        console.log(this.config.minDate);
        // if (!this.config.minDate) {
        this.config.minDate = null;
        // }
        console.log('jaimin')
    }
    
    setDefaultImage(event: Event) {
        const target = event.target as HTMLImageElement;
        target.src = 'assets/images/logop2g1.png';
      }
}
