import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpModule } from '@angular/http';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { LoginComponent, ForgotComponent, ResetComponent, ConfirmComponent } from './index';
import { AppService, AuthGuard } from './directive/index';
import { LayoutModule } from '../app/layout/layout.module';
import { BusyModule } from 'angular2-busy';


const appRoutes: Routes = [
    {
        path: '',
        component: LayoutModule
    },
    { path: 'login', component: LoginComponent },
    { path: 'reset/:id', component: ResetComponent },
    { path: 'confirm_verification/:id', component: ConfirmComponent },
    { path: 'forgot', component: ForgotComponent }
];

@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        ForgotComponent,
        ResetComponent,
        ConfirmComponent
    ],

    imports: [
        BrowserModule,
        FormsModule,
        BrowserAnimationsModule,
        BusyModule,
        HttpModule,
        RouterModule.forRoot(appRoutes),
        NgbModule,
        LayoutModule,

    ],

    providers: [AppService, AuthGuard],

    bootstrap: [AppComponent]

})

export class AppModule { }