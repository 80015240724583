import { Component, ViewContainerRef } from '@angular/core';
import { Router } from '@angular/router';
import { routerTransition } from '../../router.animations';
import { MalihuScrollbarService } from 'ngx-malihu-scrollbar';
import { AppService } from '../../directive/app.service';
import { Action, AppConstants } from '../../directive/app.constants';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
// import {ToastsManager} from 'ng2-toastr/ng2-toastr';

@Component({
    selector: 'add-admins',
    templateUrl: './add-admins.html',
    styleUrls: ['./add-admins.component.scss'],
    animations: [routerTransition()]
})

export class AddAdminsComponent {
    busy: Subscription;
    title = 'app';
    public scrollbarOptions = { axis: 'y', theme: 'minimal-light', scrollButtons: { enable: true } };
    show_list: any[] = ['1', '2', '3', '4'];
    dropdownList = [];
    dropdownSettings = {};
    selectedItems = [];
    data = [];
    Button: string = 'Submit';
    show: boolean = true;
    Title: string = 'Add Contract Admin';
    adminuser = {
        vFirstName: '',
        vLastName: '',
        vEmail: '',
        vPassword: '',
        vConfirmPassword: '',
        vPhoneNumber: '',
        vOrganizationName: '',
        message: ''
    }
    constructor(public router: Router, private appService: AppService, public toastr: ToastrService, vcr: ViewContainerRef) {
        // this.toastr.setRootViewContainerRef(vcr);
        this.dropdownSettings = {
            singleSelection: false,
            badgeShowLimit: 1,
            text: "Select Contract",
        };
        if (localStorage.getItem('adminData')) {
            let data = JSON.parse(localStorage.getItem('adminData'));
            this.show = false;
            this.Button = 'Update';
            this.Title = 'Update Contract Admin';
            this.adminuser.vFirstName = data._id.vFirstName;
            this.adminuser.vLastName = data._id.vLastName;
            this.adminuser.vEmail = data._id.vEmail;
            this.adminuser.vPassword = data._id.vPassword;
            this.adminuser.vPhoneNumber = data._id.vPhoneNumber;
            this.adminuser.vOrganizationName = data._id.vOrganizationName;
            for (let i = 0; i < data._id.vContracts.length; i++) {
                this.selectedItems.push({ 'id': data._id.vContracts[i].iContractID, 'itemName': data._id.vContracts[i].vContractName });
            }
        }
    }
    ngOnInit() {
        let action = Action.GET_ADMIN_CONTRACT;
        let fd = new FormData();
        fd.append("iContractID", '');
        fd.append("eFlag", AppConstants.CONTRACT_ADMIN_EFLAG);
        this.appService.remontServiceCall(action, fd)
            .subscribe(data => {
                if (data.type == true) {
                    this.dropdownList = data.resultData;
                } else {
                }
            }, error => {
            });
    }
    submitForm() {
        if (this.Button == 'Update') {
            let data = JSON.parse(localStorage.getItem('adminData'));
            let action = Action.UPDATE_ADMIN_USER;
            let fd = new FormData();
            fd.append("iUserID", data._id._id);
            fd.append("vFirstName", this.adminuser.vFirstName);
            fd.append("vLastName", this.adminuser.vLastName);
            fd.append("vEmail", this.adminuser.vEmail);
            fd.append("vPhoneNumber", this.adminuser.vPhoneNumber);
            fd.append("vOrganizationName", this.adminuser.vOrganizationName);
            fd.append("Contracts", JSON.stringify(this.selectedItems));
            this.busy = this.appService.remontServiceCall(action, fd)
                .subscribe(data => {
                    if (data.type == true) {
                        this.toastr.success(data.message);
                        this.router.navigate(['/admins']);
                    } else {
                        this.toastr.error(data.message);
                    }
                }, error => {

                });
        } else {
            let action = Action.ADD_ADMIN_USER;
            let fd = new FormData();
            fd.append("vFirstName", this.adminuser.vFirstName);
            fd.append("vLastName", this.adminuser.vLastName);
            fd.append("vEmail", this.adminuser.vEmail);
            fd.append("vPassword", this.adminuser.vPassword);
            fd.append("vConfirmPassword", this.adminuser.vConfirmPassword);
            fd.append("vPhoneNumber", this.adminuser.vPhoneNumber);
            fd.append("vOrganizationName", this.adminuser.vOrganizationName);
            fd.append("Contracts", JSON.stringify(this.selectedItems));
            this.busy = this.appService.remontServiceCall(action, fd)
                .subscribe(data => {
                    if (data.type == true) {
                        this.toastr.success(data.message);
                        this.router.navigate(['/admins']);
                    } else {
                        this.toastr.error(data.message);
                    }
                }, error => {

                });
        }
    }
}