import { Component, ViewContainerRef } from '@angular/core';
import { Router } from '@angular/router';
import { routerTransition } from '../../router.animations';
import { MalihuScrollbarService } from 'ngx-malihu-scrollbar';
import { AppService } from '../../directive/app.service';
import { Action, AppConstants } from '../../directive/app.constants';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
// import {ToastsManager} from 'ng2-toastr/ng2-toastr';
import { NgxMasonryOptions } from 'ngx-masonry';

@Component({
    selector: 'admins',
    templateUrl: './admins.html',
    styleUrls: ['./admins.component.scss'],
    animations: [routerTransition()]
})

export class AdminsComponent {
    public masonryOptions: NgxMasonryOptions = {
        transitionDuration: '0s',
        horizontalOrder: true
        // gutter: 20,
        // resize: true,
        // initLayout: true,
        // fitWidth: true
    };
    busy: Subscription;
    title = 'app';
    public scrollbarOptions = { axis: 'y', theme: 'minimal-dark', scrollButtons: { enable: true } };
    show_list: any[] = ['10', '20', '30', '40'];
    search_text: string = '';
    show_contract: any;
    page: any = 1;
    totalItem: number;
    timer: any = null;
    constructor(public router: Router, private appService: AppService, public toastr: ToastrService, vcr: ViewContainerRef) {
        // this.toastr.setRootViewContainerRef(vcr);
        this.show_contract = '10';
        if (AppConstants.HISTROY_PAGE) {
            this.show_contract = AppConstants.HISTROY_PAGE.show_contract;
            this.page = AppConstants.HISTROY_PAGE.page;
            this.search_text = AppConstants.HISTROY_PAGE.search_text;
            AppConstants.HISTROY_PAGE = null;
        }
    }
    adminsData: Array<any> = [];
    ngOnInit() {

        // let skip = '0';
        // if (this.page > 1) {
        //     skip = Math.round(((this.page - 1) * this.show_contract)).toFixed(2);
        // }
        var selectedStatus = [];

        let action = Action.GET_ADMINS_USER;
        let fd = new FormData();
        fd.append("search_text", this.search_text);
        fd.append("eFlag", '1');
        fd.append("iContractID", AppConstants.CONTRACT_ADMIN_CONTRACT);
        fd.append("eStatus", JSON.stringify(selectedStatus));
        fd.append("skip", this.page);
        fd.append("limit", this.show_contract);
        this.busy = this.appService.remontServiceCall(action, fd)
            .subscribe(data => {
                if (data.type == true) {
                    this.adminsData = data.resultData;
                    this.totalItem = data.totalItem;

                } else {
                    console.log("data.resultData");

                }
            }, error => {

            });

    }
    pageChange(event) {
        this.page = event;
        this.ngOnInit();
    }
    searchUserContract() {
        clearTimeout(this.timer);
        var me = this;
        this.timer = setTimeout(function () {
            me.page = event;
            me.ngOnInit()
        }, 500);
        // this.ngOnInit();
    }
    getContracts(Contract) {
        let data = [];
        if (Contract) {
            for (let j = 0; j < Contract.length; j++) {
                if (Contract[j].vContractName != undefined) {
                    data.push(Contract[j].vContractName);
                }
            }
            let contractNameString = data.join(',');
            return contractNameString;
        }
    }

    activeDeactiveUserAdmin(id, event) {
        let status;
        if (event.target.checked == true) {
            status = '0';
        }
        else {
            status = '1';
        }
        let fd = new FormData();
        let action = Action.ACTIVE_ADMIN_USER;
        fd.append("estatus", status);
        fd.append("iUserID", id);
        this.busy = this.appService.remontServiceCall(action, fd)
            .subscribe(data => {
                if (data.type == true) {
                    if (event.target.checked == true) {
                        this.toastr.success('Contract admin activated successfully.');
                    } else {
                        this.toastr.success('Contract admin deactivated successfully.');
                    }

                    this.ngOnInit();
                } else {
                    console.log('data.message');
                }
            }, error => {

            });
    }
    loginPermissionAsGenerelUser(id, event) {
        let status;
        let fd = new FormData();
        let action = Action.UPDATE_LOGIN_PERMISSION;
        fd.append("eLoginStatus", event.target.checked);
        fd.append("iUserID", id);
        this.busy = this.appService.remontServiceCall(action, fd)
            .subscribe(data => {
                if (data.type == true) {
                    if (event.target.checked == true) {
                        this.toastr.success('Permission is approved');
                    } else {
                        this.toastr.success('Permission is rejected');
                    }

                    this.ngOnInit();
                } else {
                    console.log('data.message');
                }
            }, error => {

            });
    }
    deleteUserAdmin(id) {
        let fd = new FormData();
        fd.append("iUserID", id);
        fd.append("status", '1');
        let action = Action.DELETE_ADMIN_USER;
        this.busy = this.appService.remontServiceCall(action, fd)
            .subscribe(data => {
                if (data.type == true) {
                    this.toastr.success(data.message);
                    this.ngOnInit();
                } else {
                    console.log(data.message);
                }
            }, error => {

            });

    }
    editAdminUser(admin) {
        localStorage.setItem('adminData', JSON.stringify(admin));
        this.router.navigate(['/admins/add']);
    }
    addAdminUser() {
        localStorage.setItem('adminData', '');
        this.router.navigate(['/admins/add']);
    }
    showIssues(value) {
        let param = { 'search_text': this.search_text, 'show_contract': this.show_contract, 'page': this.page }
        AppConstants.HISTROY_PAGE = param;
        let contract = [];
        for (let j = 0; j < value.vContracts.length; j++) {
            contract.push(value.vContracts[j].iContractID);
        }
        let i = contract.join(',');
        // AppConstants.TEMP_USER = { 'id': i, itemName: value.vFirstName + ' ' + value.vLastName };
        AppConstants.TEMP_GENERAL_USER = value._id;
        AppConstants.TEMP_GENERAL_USER_NAME = 'by ' + value.vFirstName + ' ' + value.vLastName;
    }

}